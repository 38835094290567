/**
 * File: status.js - A component that display the log history
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import DOMPurify from 'dompurify';
import React from 'react';
import { isHTML } from '../../common/functions';
import WireFabricationReviewSummary from '../workflow/production/wire_fabrication_review_summary';

const Log = ({ log_value, log_type }) => {
  const allowedLogStatuses = (status) => {
    let allowed = false;

    if (log_type === 'patient') {
      switch (status) {
        case 'Case Submitted':
        case 'Digital Enhancement Submitted':
        case 'Doctor Uploaded New Files':
        case 'Awaiting Folder Generation Process':
        case 'Folder Generation in Process':
        case 'Folder Created':
        case 'Folder Generation Failed':
        case 'Segmentation in Progress':
        case 'Segmentation Complete':
        case 'Segmentation Incomplete':
        case 'INBRACE Verifying Records':
        case 'Doctor Uploaded New Records':
        case 'Records Marked Incomplete':
        case 'Records Verification Complete':
        case 'Awaiting New Scans':
        case 'InBrace Verifying Records':
          allowed = true;
          break;
        default:
      }
    } else if (log_type === 'setup') {
      if (
        status.includes('INBRACE Preparing Setup') ||
        status.includes('INBRACE Prepared Setup') ||
        status.includes('InBrace Preparing Smile Design') ||
        status.includes('Final Design Upload') ||
        status.includes('Final Design in Conversion') ||
        status.includes('Final Design Conversion Failed') ||
        status.includes('Final Design Recipe') ||
        status.includes('Final Design Ready for Release') ||
        status.includes('Final Design Review')
      ) {
        allowed = true;
      }

      switch (status) {
        case 'INBRACE Preparing Setup':
        case 'Setup Revision Completed':
        case 'Setup Prep Completed':
        case 'Setup in Review':
        case 'Setup to be Revised':
        case 'Setup in Revision':
        case 'Setup Ready for Upload':
        case 'Setup Review Completed':
        case 'Setup Release Rejected':
        case 'Setup Upload Completed':
        case 'Setup in Conversion':
        case 'Setup Conversion Failed':
        case 'Setup Conversion Completed':
        case 'Setup Ready for Release':
        case 'Setup Released':
        case 'Awaiting Doctor Approval':
        case 'Doctor Requested Revision':
        case 'Doctor Approved Setup':
        case 'InBrace Preparing Smile Design':
        case 'Smile Design Revision Completed':
        case 'Smile Design Prep Completed':
        case 'Smile Design in Review':
        case 'Smile Design to be Revised':
        case 'Smile Design in Revision':
        case 'Smile Design Ready for Upload':
        case 'Smile Design Review Completed':
        case 'Smile Design Release Rejected':
        case 'Smile Design Upload Completed':
        case 'Smile Design in Conversion':
        case 'Smile Design Conversion Failed':
        case 'Smile Design Conversion Completed':
        case 'Smile Design Ready for Release':
        case 'Smile Design Released':
        case 'Doctor Approved Smile Design':
        case 'Provider Edited Smile Design':
        case 'Provider Edited Smile Design Internally Approved':
        case 'Provider Edited Smile Design Internally Rejected':
        case 'InBrace IFS Recipe':
        case 'InBrace IFS Recipe Completed':
        case 'InBrace IFS Smile Design Ready for Upload':
        case 'InBrace IFS Upload Completed':
        case 'InBrace IFS Conversion Failed':
        case 'InBrace IFS Conversion Completed':
        case 'InBrace IFS Ready for Release':
        case 'InBrace IFS Release Rejected':
        case 'InBrace IFS Released':
        case 'InBrace IFS Review':
        case 'Provider Edit Ready for Upload':
        case 'Provider Edit Upload Completed':
        case 'Provider Edit Conversion Failed':
        case 'Provider Edit Conversion Completed':
        case 'Provider Edit Ready for Release':
        case 'Provider Edit Release Rejected':
        case 'Provider Edit Released':
        case 'InBrace IFS (Provider Edit) Recipe':
        case 'InBrace IFS (Provider Edit) Recipe Completed':
        case 'InBrace IFS (Provider Edit) Ready for Upload':
        case 'InBrace IFS (Provider Edit) Upload Completed':
        case 'InBrace IFS (Provider Edit) Conversion Failed':
        case 'InBrace IFS (Provider Edit) Conversion Completed':
        case 'InBrace IFS (Provider Edit) Ready for Release':
        case 'InBrace IFS (Provider Edit) Release Rejected':
        case 'InBrace IFS (Provider Edit) Released':
        case 'InBrace IFS (Provider Edit) Review':
          allowed = true;
          break;
        default:
      }
    } else if (log_type === 'appliance_design') {
      switch (status) {
        case 'Appliance Design Process Completed':
        case 'Appliance Design in Process':
        case 'Stage I in Process':
        case 'Stage I Completed':
        case 'Stage I Rejected':
        case 'Quality Control Stage I in Process':
        case 'Quality Control Stage I Completed':
        case 'Stage II Completed':
        case 'Stage II in Process':
        case 'Stage III Completed':
        case 'Stage III in Process':
        case 'Quality Control Review':
        case 'Quality Control Rejected':
        case 'Quality Control Review Completed':
        case 'Return to Stage I':
          allowed = true;
          break;
        default:
      }
    } else if (log_type === 'wire_selection') {
      const allowed_status_verbiage = ['Wire Selection', 'Custom Wire WO Creation', 'Wire Generation'];
      if (hasAllowedStatusVerbiage(allowed_status_verbiage, status)) allowed = true;
    } else if (log_type === 'wire_manufacturing') {
      const allowed_status_verbiage = ['Wire Cutting', 'Wire Awaiting Pick Up', 'Wire Picked Up'];
      if (hasAllowedStatusVerbiage(allowed_status_verbiage, status)) allowed = true;
    } else if (log_type === 'production') {
      const allowed_status_verbiage = ['Wire Fabrication'];
      if (hasAllowedStatusVerbiage(allowed_status_verbiage, status)) allowed = true;

      switch (status) {
        case 'Label Generation Started':
        case 'Label Generation Failed':
        case 'Label Generation Completed':
        case 'Label Generation Retried':
          allowed = true;
          break;
        default:
      }
    }

    return allowed;
  };

  /**
   * Determines if given status has allowed verbiage to be displayed
   * @function
   * @param {list} allowed_status_verbiage - List of allowed verbiage
   * @param {string} status - Status
   * @returns {boolean} - True or false
   */
  const hasAllowedStatusVerbiage = (allowed_status_verbiage, status) => {
    return allowed_status_verbiage.some((s) => status.includes(s));
  };

  /**
   * Translate Log before display
   *
   * @function
   * @param {string} log - Log status
   * @returns {boolean} - Transltated log status
   */
  const translateLogs = (log) => {
    switch (log) {
      case 'Quality Control Stage I in Process':
        return 'Stage I Review in Process';

      case 'Quality Control Stage I Completed':
        return 'Stage I Review Completed';

      case 'Provider Edited Smile Design':
        return 'Doctor Requested Revision';

      case 'Provider Edited Smile Design Internally Approved':
        return 'InBrace Approved Provider Edit';

      case 'Provider Edited Smile Design Internally Rejected':
        return 'Provider Edit to be Revised';

      default:
        return log;
    }
  };

  return log_value.map((status, index) => {
    const date = status.date ? status.date : log_value[index - 1] ? log_value[index - 1].date : 'N/A';

    return allowedLogStatuses(status.status) && status.date !== '--' ? (
      <div style={{ textAlign: 'left' }} key={index}>
        <span className="bold-text min-date-width">{date}</span>
        <span>
          {translateLogs(status.status)}
          <span className="bold-text">
            {isHTML(status.comment) || status.status === 'Setup Released' ? (
              <div
                className="ql-editor ql-table rich-logs"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(status.comment, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                }}
              />
            ) : (
              <span className="ql-editor ql-table">{status.comment ? ' - ' + status.comment : ''}</span>
            )}
          </span>
        </span>
        {status.wires && <WireFabricationReviewSummary failed_wires={status.wires} is_log={true} />}
      </div>
    ) : null;
  });
};

export default Log;
