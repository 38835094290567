import React, { useContext, useEffect } from 'react';
import Button from '../../setup_viewer/components/extras/button';
import { connect } from 'react-redux';
import { getCaseDetails } from '../../../redux/reducers/common/common_case_details';
import { UserPermissionsContext } from '../../../context/user_permission';
import { userHasPermission } from '../../../common/permission';
import { getButtonClearance, TOOLTIP_VERBIAGES } from './approval_button_helpers';

/**
 * Wasm Sidebar Approval Buttons component.
 * @param {Object} props - Component props.
 * @param {Function} props.onClickApprove - Click handler for the Approve button.
 * @param {Function} props.onClickRevise - Click handler for the Revise button.
 * @param {Object} props.case_details - Case details object.
 * @param {boolean} props.disabled - Whether the buttons are disabled.
 * @returns {React.Element} - React component.
 */
function WasmSidebarApprovalButtons({ onClickApprove, onClickRevise, case_details, disabled }) {
  const { approved_disabled, revise_disabled, approve_tooltip } = getButtonClearance(case_details);
  const { permissions } = useContext(UserPermissionsContext);

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <>
      {userHasPermission('IPP_SETUP_APPROVAL', permissions) && (
        <Button theme="success" onClick={onClickApprove} disabled={approved_disabled || disabled}>
          <div data-toggle="tooltip" data-placement="top" title={approve_tooltip} data-html>
            Approve
          </div>
        </Button>
      )}
      {userHasPermission('IPP_SETUP_REVISION', permissions) && (
        <Button theme="danger" onClick={onClickRevise} disabled={revise_disabled || disabled}>
          <div className="revise-btn" data-toggle="tooltip" data-placement="right" title={TOOLTIP_VERBIAGES.REVISE_TOOLTIP}>
            Revise
          </div>
        </Button>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
  };
};

export default connect(mapStateToProps)(WasmSidebarApprovalButtons);
