import { CaseStatus } from './case_details.constants';

/**
 * Determines if case is in Smile Design stage before doctor approval
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isSmileDesignStage = (status) => {
  const statuses = [
    CaseStatus.InBraceSetup,
    CaseStatus.ReviseSetup,
    CaseStatus.SetupReview,
    CaseStatus.SetupUploadReady,
    CaseStatus.SetupConversion,
    CaseStatus.SetupConversionFailed,
    CaseStatus.SetupReleaseReady,
  ];
  return status && statuses.indexOf(status) !== -1;
};

/**
 * Determines if case is in Post Approval stage
 * @function
 * @param {string} status - Current case status
 * @param {boolean} include_review_status - Include Provider Edit Review status
 * @returns {Boolean} True or false
 */
const isPostApprovalStage = (status, include_review_status = true) => {
  const statuses_without_review_status = [
    CaseStatus.IFSUpload,
    CaseStatus.IFSInConversion,
    CaseStatus.IFSConversionFailed,
    CaseStatus.IFSRecipe,
    CaseStatus.IFSReadyForRelease,
    CaseStatus.IFSReview,
  ];

  const statuses = [CaseStatus.ProviderEdit, ...statuses_without_review_status];

  if (include_review_status) return statuses.includes(status);
  else return statuses_without_review_status.includes(status);
};

/**
 * Determines if case is in Appliance Design stage
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isApplianceDesignStage = (status) => {
  const statuses = [CaseStatus.ApplianceDesign, CaseStatus.QualityControlReview];
  return status && statuses.indexOf(status) !== -1;
};

/**
 * Determines if case is before Fabrication stage
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isBeforeFabricatingStage = (status) => {
  return !isFabricatingStage(status) && status !== CaseStatus.CaseShipped;
};

/**
 * Determines if case is in fabricating stage
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isFabricatingStage = (status) => {
  const statuses = [CaseStatus.ManufacturingCase, CaseStatus.StatusManufacturing];
  return status && statuses.indexOf(status) !== -1;
};

/**
 * Determines if case is after smile design approval
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isAfterSmileDesignApproval = (status) => {
  return isApplianceDesignStage(status) || isFabricatingStage(status) || status === CaseStatus.CaseShipped || isPostApprovalStage(status, false);
};

/**
 * Determines if case is not blocked or inactive
 * @function
 * @param {string} status - Current case status
 * @returns {Boolean} True or false
 */
const isNotBlockedOrInactive = (status) => {
  const invalid_statuses = [CaseStatus.StatusShip, CaseStatus.StatusHold, CaseStatus.StatusCancel, CaseStatus.DoctorProvideClarification];
  return status && invalid_statuses.indexOf(status) === -1;
};

const isIFSReadyForRelease = (currentCase) => {
  const setupProcess = currentCase?.setup_process?.at(-1);
  const lastIFSStatus = currentCase.workflow_version === '1.0' ? CaseStatus.IFSReadyForRelease : CaseStatus.IFSReview;
  const lastIFSIndex = currentCase?.case_statuses.findLastIndex((c) => c.case_status === lastIFSStatus);

  const lastDoctorApproveOrReviseIndex = currentCase?.case_statuses.findLastIndex((c) => c.case_status === CaseStatus.DoctorApproveOrReviseSetup);
  const lastIFSFailedIndex = currentCase?.case_statuses.findLastIndex((c) => c.case_status === CaseStatus.IFSConversionFailed);
  const lastIFSUploadIndex = currentCase?.case_statuses.findLastIndex((c) => c.case_status === CaseStatus.IFSUpload);
  const lastIFSRecipeIndex = currentCase?.case_statuses.findLastIndex((c) => c.case_status === CaseStatus.IFSRecipe);
  const isIFSReady =
    lastIFSIndex > lastDoctorApproveOrReviseIndex &&
    lastIFSIndex > lastIFSUploadIndex &&
    lastIFSIndex > lastIFSFailedIndex &&
    lastIFSIndex > lastIFSRecipeIndex;
  console.log('isIFSReady', isIFSReady);
  return isIFSReady && setupProcess?.is_inbrace_ifs_setup;
};

export {
  isPostApprovalStage,
  isApplianceDesignStage,
  isNotBlockedOrInactive,
  isSmileDesignStage,
  isFabricatingStage,
  isAfterSmileDesignApproval,
  isBeforeFabricatingStage,
  isIFSReadyForRelease,
};
