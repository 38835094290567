import '../setup/setup_release.scss';

import { SetupSmileDesignPreferences, SetupAccordionsWrapper } from '../setup/setup_helper';

// External Libs
import React from 'react';
import Modal from '../../modal/modal';
import ProcessComponent from '../process_component';

// Internal Libs
import { isCancelOrHoldNextStatus } from '../../../common/helpers';
import { removeCaseIdInitialNumber, textFieldCheck, removeEmoji } from '../../../common/functions';

/**
 * Post Approval Smile Design Review Process component
 */
class PostApprovalReview extends ProcessComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRevisionModal: false,
      showApproveModal: false,
      comment: '',
    };
  }

  enterSetupReleaseProcess = (event) => {
    this.taskRunner('onSetupReviewApprovedClick', event).then((result) => {
      if (result) {
        this.setState({
          showApproveModal: false,
        });
        this.props.reload_information();
      } else if (result && result.response && result.response.status >= 400) {
        this.setState({
          showApproveModal: false,
        });
      }
    });
  };

  toggleReleaseModal = () => {
    this.setState({ showApproveModal: !this.state.showApproveModal });
  };

  onApproveClick = (event) => {
    this.toggleReleaseModal();
  };

  onCancelReleaseModal = (event) => {
    this.toggleReleaseModal();
  };

  enterSetupRevisionProcess = (event) => {
    this.taskRunner('onSetupReviseClick', this.state.comment).then((result) => {
      if (result) {
        this.setState({
          showApproveModal: false,
        });
        this.props.reload_information();
      } else if (result && result.response && result.response.status >= 400) {
        this.setState({
          showApproveModal: false,
        });
      }
    });
  };

  toggleRevisionModal = () => {
    this.setState({ showRevisionModal: !this.state.showRevisionModal });
  };

  revisionModal = () => {
    const header_text = `Revise Provider Edit - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision-dialog"
        header_text={header_text}
        message_text="Are you sure you would like the Provider Edit to be revised?"
        textarea_placeholder="Specify what needs to be revised for this Provider Edit"
        textarea_warning_text="Please specify reason for revision"
        confirm_btn_text="Confirm"
        onConfirmButtonClick={this.enterSetupRevisionProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelRevisionModal}
        onTextAreaChange={this.onCommentChange}
      />
    );
  };

  onCommentChange = (event) => {
    const comment = removeEmoji(textFieldCheck(event.target.value));
    this.setState({ comment: comment });
  };
  onReviseClick = (event) => {
    this.toggleRevisionModal();
  };

  onCancelRevisionModal = (event) => {
    this.toggleRevisionModal();
  };

  toggleReleaseModal = () => {
    this.setState({ showApproveModal: !this.state.showApproveModal });
  };

  approveModal = () => {
    const header_text = `Approve Provider Edit - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to approve the provider edits?"
        confirm_btn_text="Approve"
        onConfirmButtonClick={this.enterSetupReleaseProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelReleaseModal}
      />
    );
  };

  /**
   * Handles opening the setup viewer window
   * @function
   * @param {Number} index - The setup index
   */
  onSetupClick = (event) => {
    const { case_id } = this.props;
    const { is_wasm_viewer_enabled } = this.props.cases;
    const url = is_wasm_viewer_enabled ? `/smile_design/${case_id}` : `/setup/${case_id}/${this.props.setup.index}`;
    window.open(url);
  };

  onCancelReleaseModal = (event) => {
    this.toggleReleaseModal();
  };

  displayUnauthorizedView = (latest_setup) => {
    return (
      <div className="setup-release">
        <div className="setup-release-instructions font-size-reg">The provider's edited Smile Design is currently in review...</div>

        <div className="setup-release-container">
          <div className="setup-release-heading">Smile Design {latest_setup?.index} (Provider Edit):</div>
          {(this.props.workflow_version === '1.0' ? latest_setup?.files && Object.keys(latest_setup?.files).length > 0 : true) && (
            <div key={this.props.log_index} className="business-dark-setup-window center-text">
              <div className="relative">
                <img
                  className="setup-preview"
                  data-setup={latest_setup.index}
                  src={process.env.PUBLIC_URL + '/static/img/setup.jpg'}
                  onClick={this.onSetupClick}
                  alt={'setup' + latest_setup.index}
                />
              </div>
              <div className="setup_img_mask" onClick={this.onSetupClick}>
                Provider Edit
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const preferences_accepted = this.props.doctor_smile_design_preferences?.accepted;
    const latest_setup = this.props.setup_process[this.props.setup_process.length - 1];
    const maskText = latest_setup.is_inbrace_ifs_setup ? 'InBrace IFS Required (Provider Edit)' : 'Provider Edit';
    return this.props.has_post_approval_edit_permission ? (
      <div className={isCancelOrHoldNextStatus(this.props.last_stage, this.props.stage) ? 'hide-element' : 'setup-release'}>
        <SetupAccordionsWrapper>{preferences_accepted && <SetupSmileDesignPreferences {...this.props} />}</SetupAccordionsWrapper>
        <div className="setup-release-instructions font-size-reg">
          {this.props.workflow_version === '1.0' ? (
            <>
              Once the Provider Edited Smile Design has been reviewed, click Approve to proceed to the <br />
              Upload step or click Revise to have Smile Designer revise the Smile Design
            </>
          ) : (
            <>
              Once the Provider Edited Smile Design has been reviewed, export from SDS and click Approve to proceed <br />
              or click Revise to have Smile Designer revise the Smile Design
            </>
          )}
        </div>

        <div className="setup-release-container">
          <div className="setup-release-heading">Smile Design {latest_setup?.index} (Provider Edit):</div>
          {(this.props.workflow_version === '1.0' ? latest_setup?.files && Object.keys(latest_setup?.files).length > 0 : true) && (
            <div key={this.props.log_index} className="business-dark-setup-window center-text">
              <div className="relative">
                <img
                  className="setup-preview"
                  data-setup={latest_setup.index}
                  src={process.env.PUBLIC_URL + '/static/img/setup.jpg'}
                  onClick={this.onSetupClick}
                  alt={'setup' + latest_setup.index}
                />
              </div>
              <div className="setup_img_mask" onClick={this.onSetupClick}>
                {maskText}
              </div>
            </div>
          )}
        </div>

        <div className="button-panel">
          <button
            type="button"
            className="btn btn-light"
            onClick={this.onApproveClick}
            disabled={this.props.setup_upload && !(this.props.setup_upload.length > 0)}
          >
            Approve
          </button>

          <button
            type="button"
            className="btn btn-light"
            onClick={this.onReviseClick}
            disabled={this.props.setup_upload && !(this.props.setup_upload.length > 0)}
          >
            Revise
          </button>
        </div>
        {this.state.showApproveModal ? this.approveModal() : null}
        {this.state.showRevisionModal ? this.revisionModal() : null}
      </div>
    ) : (
      this.displayUnauthorizedView(latest_setup)
    );
  }
}

export default PostApprovalReview;
