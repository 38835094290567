/**
 * This file contains nomenclature constants to be imported, and lookup
 * dictionary to used by getLookup()
 */

const GEN_2 = 'Gen 2.0';

const UPPER = 'Upper';
const LOWER = 'Lower';
const IDB_TRAY = 'IDB Tray';
const SMARTWIRE = 'Smartwire';
const PVS = '(PVS)';
const THREEDP = '(3DP)';
const DE = 'DE';
const INITIAL = 'Initial';
const SINGLE_TOOTH_IDB_TRAY = 'Single-Tooth IDB Tray';

export const ZERO = '0';
export const ONE = '1';
export const TWO = '2';
export const TWOA = '2A';
export const THREE = '3';

// brackets
export const REPLACEMENT_BRACKET = 'Replacement Bracket';
export const REPLACEMENT_BRACKETS = 'Replacement Brackets';
export const STANDARD_BRACKET = 'Standard Bracket';
export const MOLAR_BRACKET = 'Molar Bracket';
export const LOWER_ANTERIOR_BRACKET = 'Lower Anterior Bracket';
export const UPPER_ANTERIOR_BRACKET = 'Upper Anterior Bracket';
export const SMALL_POSTERIOR_BRACKET = 'Small Posterior Bracket';
export const LARGE_POSTERIOR_BRACKET = 'Large Posterior Bracket';

// bond for later
export const BOND_FOR_LATER = 'Bond For Later';

// smartwire
export const SMARTWIRE_0 = `${SMARTWIRE} ${ZERO}`;
export const SMARTWIRE_1 = `${SMARTWIRE} ${ONE}`;
export const SMARTWIRE_2 = `${SMARTWIRE} ${TWO}`;
export const SMARTWIRE_2A = `${SMARTWIRE} ${TWOA}`;
export const SMARTWIRE_3 = `${SMARTWIRE} ${THREE}`;

// de
export const DE_SMARTWIRE_0 = `${DE} ${SMARTWIRE_0}`;
export const DE_SMARTWIRE_1 = `${DE} ${SMARTWIRE_1}`;
export const DE_SMARTWIRE_2 = `${DE} ${SMARTWIRE_2}`;
export const DE_SMARTWIRE_2A = `${DE} ${SMARTWIRE_2A}`;
export const DE_SMARTWIRE_3 = `${DE} ${SMARTWIRE_3}`;

// upper
export const UPPER_IDB_TRAY = `${UPPER} ${IDB_TRAY}`;
export const UPPER_IDB_TRAY_PVS = `${UPPER} ${IDB_TRAY} ${PVS}`;
export const UPPER_IDB_TRAY_THREEDP = `${UPPER} ${IDB_TRAY} ${THREEDP}`;
export const UPPER_SMARTWIRE_0 = `${UPPER} ${SMARTWIRE_0}`;
export const UPPER_SMARTWIRE_1 = `${UPPER} ${SMARTWIRE_1}`;
export const UPPER_SMARTWIRE_2 = `${UPPER} ${SMARTWIRE_2}`;
export const UPPER_SMARTWIRE_3 = `${UPPER} ${SMARTWIRE_3}`;
export const UPPER_IDB_TRAY_GEN_2 = `${UPPER} ${INITIAL} ${IDB_TRAY}, ${GEN_2}`;
export const UPPER_IDB_TRAY_GEN_2_IPP = `${UPPER} ${INITIAL} ${IDB_TRAY}`;
export const UPPER_SMARTWIRE_0_GEN_2 = `${UPPER_SMARTWIRE_0}, ${GEN_2}`;
export const UPPER_SMARTWIRE_1_GEN_2 = `${UPPER_SMARTWIRE_1}, ${GEN_2}`;
export const UPPER_SMARTWIRE_2_GEN_2 = `${UPPER_SMARTWIRE_2}, ${GEN_2}`;
export const UPPER_SMARTWIRE_3_GEN_2 = `${UPPER_SMARTWIRE_3}, ${GEN_2}`;
export const UPPER_SINGLE_TOOTH_IDB_TRAY = `${UPPER} ${SINGLE_TOOTH_IDB_TRAY}`;

// lower
export const LOWER_IDB_TRAY = `${LOWER} ${IDB_TRAY}`;
export const LOWER_IDB_TRAY_PVS = `${LOWER} ${IDB_TRAY} ${PVS}`;
export const LOWER_IDB_TRAY_THREEDP = `${LOWER} ${IDB_TRAY} ${THREEDP}`;
export const LOWER_SMARTWIRE_0 = `${LOWER} ${SMARTWIRE_0}`;
export const LOWER_SMARTWIRE_1 = `${LOWER} ${SMARTWIRE_1}`;
export const LOWER_SMARTWIRE_2 = `${LOWER} ${SMARTWIRE_2}`;
export const LOWER_SMARTWIRE_2A = `${LOWER} ${SMARTWIRE_2A}`;
export const LOWER_SMARTWIRE_3 = `${LOWER} ${SMARTWIRE_3}`;
export const LOWER_IDB_TRAY_GEN_2_IPP = `${LOWER} ${INITIAL} ${IDB_TRAY}`;
export const LOWER_IDB_TRAY_GEN_2 = `${LOWER} ${INITIAL} ${IDB_TRAY}, ${GEN_2}`;
export const LOWER_SMARTWIRE_0_GEN_2 = `${LOWER_SMARTWIRE_0}, ${GEN_2}`;
export const LOWER_SMARTWIRE_1_GEN_2 = `${LOWER_SMARTWIRE_1}, ${GEN_2}`;
export const LOWER_SMARTWIRE_2_GEN_2 = `${LOWER_SMARTWIRE_2}, ${GEN_2}`;
export const LOWER_SMARTWIRE_3_GEN_2 = `${LOWER_SMARTWIRE_3}, ${GEN_2}`;
export const LOWER_SINGLE_TOOTH_IDB_TRAY = `${LOWER} ${SINGLE_TOOTH_IDB_TRAY}`;

// upper de
export const UPPER_DE_IDB_TRAY = `${UPPER} ${DE} ${IDB_TRAY}`;
export const UPPER_DE_IDB_TRAY_PVS = `${UPPER} ${DE} ${IDB_TRAY} ${PVS}`;
export const UPPER_DE_IDB_TRAY_THREEDP = `${UPPER} ${DE} ${IDB_TRAY} ${THREEDP}`;
export const UPPER_DE_SMARTWIRE_0 = `${UPPER} ${DE} ${SMARTWIRE_0}`;
export const UPPER_DE_SMARTWIRE_1 = `${UPPER} ${DE} ${SMARTWIRE_1}`;
export const UPPER_DE_SMARTWIRE_2 = `${UPPER} ${DE_SMARTWIRE_2}`;
export const UPPER_DE_SMARTWIRE_3 = `${UPPER} ${DE_SMARTWIRE_3}`;
export const UPPER_DE_IDB_TRAY_GEN_2 = `${UPPER_DE_IDB_TRAY}, ${GEN_2}`;
export const UPPER_DE_SMARTWIRE_0_GEN_2 = `${UPPER_DE_SMARTWIRE_0}, ${GEN_2}`;
export const UPPER_DE_SMARTWIRE_1_GEN_2 = `${UPPER_DE_SMARTWIRE_1}, ${GEN_2}`;
export const UPPER_DE_SMARTWIRE_2_GEN_2 = `${UPPER_DE_SMARTWIRE_2}, ${GEN_2}`;
export const UPPER_DE_SMARTWIRE_3_GEN_2 = `${UPPER_DE_SMARTWIRE_3}, ${GEN_2}`;

// lower de
export const LOWER_DE_IDB_TRAY = `${LOWER} ${DE} ${IDB_TRAY}`;
export const LOWER_DE_IDB_TRAY_PVS = `${LOWER} ${DE} ${IDB_TRAY} ${PVS}`;
export const LOWER_DE_IDB_TRAY_THREEDP = `${LOWER} ${DE} ${IDB_TRAY} ${THREEDP}`;
export const LOWER_DE_SMARTWIRE_0 = `${LOWER} ${DE} ${SMARTWIRE_0}`;
export const LOWER_DE_SMARTWIRE_1 = `${LOWER} ${DE} ${SMARTWIRE_1}`;
export const LOWER_DE_SMARTWIRE_2 = `${LOWER} ${DE_SMARTWIRE_2}`;
export const LOWER_DE_SMARTWIRE_2A = `${LOWER} ${DE_SMARTWIRE_2A}`;
export const LOWER_DE_SMARTWIRE_3 = `${LOWER} ${DE_SMARTWIRE_3}`;
export const LOWER_DE_IDB_TRAY_GEN_2 = `${LOWER_DE_IDB_TRAY}, ${GEN_2}`;
export const LOWER_DE_SMARTWIRE_0_GEN_2 = `${LOWER_DE_SMARTWIRE_0}, ${GEN_2}`;
export const LOWER_DE_SMARTWIRE_1_GEN_2 = `${LOWER_DE_SMARTWIRE_1}, ${GEN_2}`;
export const LOWER_DE_SMARTWIRE_2_GEN_2 = `${LOWER_DE_SMARTWIRE_2}, ${GEN_2}`;
export const LOWER_DE_SMARTWIRE_3_GEN_2 = `${LOWER_DE_SMARTWIRE_3}, ${GEN_2}`;

// BPP
const NITI_14 = '.014 NiTi AD';
const NITI_16 = '.016 Cu-NiTi';
const NITI_16_S = '.016 Straight Cu-NiTi';
const NITI_14_HISTORIC = '.014 NiTi';
const NITI_16_HISTORIC = '.016 NiTi';
const NITI_16_S_HISTORIC = '.016 Straight NiTi';

export const BPP_UPPER_SMARTWIRE_1 = `${UPPER_SMARTWIRE_1} (${NITI_14})`;
export const BPP_UPPER_SMARTWIRE_2 = `${UPPER_SMARTWIRE_2} (${NITI_16})`;

export const BPP_LOWER_SMARTWIRE_1 = `${LOWER_SMARTWIRE_1} (${NITI_14})`;
export const BPP_LOWER_SMARTWIRE_2 = `${LOWER_SMARTWIRE_2} (${NITI_16})`;
export const BPP_LOWER_SMARTWIRE_2A = `${LOWER_SMARTWIRE_2A} (${NITI_16_S})`;

// BPP DE
export const BPP_UPPER_DE_SMARTWIRE_1 = `${UPPER_DE_SMARTWIRE_1} (${NITI_14})`;
export const BPP_UPPER_DE_SMARTWIRE_2 = `${UPPER_DE_SMARTWIRE_2} (${NITI_16})`;

export const BPP_LOWER_DE_SMARTWIRE_1 = `${LOWER_DE_SMARTWIRE_1} (${NITI_14})`;
export const BPP_LOWER_DE_SMARTWIRE_2 = `${LOWER_DE_SMARTWIRE_2} (${NITI_16})`;
export const BPP_LOWER_DE_SMARTWIRE_2A = `${LOWER_DE_SMARTWIRE_2A} (${NITI_16_S})`;

// Historic
export const BPP_UPPER_SMARTWIRE_1_HISTORIC = `${UPPER_SMARTWIRE_1} (${NITI_14_HISTORIC})`;
export const BPP_UPPER_SMARTWIRE_2_HISTORIC = `${UPPER_SMARTWIRE_2} (${NITI_16_HISTORIC})`;

export const BPP_LOWER_SMARTWIRE_1_HISTORIC = `${LOWER_SMARTWIRE_1} (${NITI_14_HISTORIC})`;
export const BPP_LOWER_SMARTWIRE_2_HISTORIC = `${LOWER_SMARTWIRE_2} (${NITI_16_HISTORIC})`;
export const BPP_LOWER_SMARTWIRE_2A_HISTORIC = `${LOWER_SMARTWIRE_2A} (${NITI_16_S_HISTORIC})`;

export const BPP_UPPER_DE_SMARTWIRE_1_HISTORIC = `${UPPER_DE_SMARTWIRE_1} (${NITI_14_HISTORIC})`;
export const BPP_UPPER_DE_SMARTWIRE_2_HISTORIC = `${UPPER_DE_SMARTWIRE_2} (${NITI_16_HISTORIC})`;

export const BPP_LOWER_DE_SMARTWIRE_1_HISTORIC = `${LOWER_DE_SMARTWIRE_1} (${NITI_14_HISTORIC})`;
export const BPP_LOWER_DE_SMARTWIRE_2_HISTORIC = `${LOWER_DE_SMARTWIRE_2} (${NITI_16_HISTORIC})`;
export const BPP_LOWER_DE_SMARTWIRE_2A_HISTORIC = `${LOWER_DE_SMARTWIRE_2A} (${NITI_16_S_HISTORIC})`;

// lookup dictionaries
// ---------------------------------------------------------------------------------
// for IPP
const IPP_LOOKUP = {
  '6502-03': UPPER_SMARTWIRE_1,
  '6500-10': UPPER_SMARTWIRE_1,
  '6502-04': LOWER_SMARTWIRE_1,
  '6500-11': LOWER_SMARTWIRE_1,
  '6500-12': UPPER_SMARTWIRE_2,
  '6501-01': UPPER_SMARTWIRE_2,
  '6500-13': LOWER_SMARTWIRE_2,
  '6501-02': LOWER_SMARTWIRE_2,
  '6500-20': LOWER_SMARTWIRE_2A,
  '6501-07': LOWER_SMARTWIRE_2A,
  '6400-05': UPPER_IDB_TRAY,
  '6400-06': LOWER_IDB_TRAY,
  '6401-02': UPPER_IDB_TRAY,
  '6401-03': LOWER_IDB_TRAY,
  '6120-04': STANDARD_BRACKET,
  '6120-02': MOLAR_BRACKET,
  '6200-05': LOWER_ANTERIOR_BRACKET,
  80028: UPPER_SMARTWIRE_0,
  80027: LOWER_SMARTWIRE_0,
  80002: UPPER_SMARTWIRE_1,
  80001: LOWER_SMARTWIRE_1,
  80004: UPPER_SMARTWIRE_2,
  80003: LOWER_SMARTWIRE_2,
  80006: UPPER_SMARTWIRE_3,
  80005: LOWER_SMARTWIRE_3,
  80008: UPPER_IDB_TRAY_GEN_2_IPP,
  80007: LOWER_IDB_TRAY_GEN_2_IPP,
  80025: 'Upper Single-Tooth IDB Tray',
  80026: 'Lower Single-Tooth IDB Tray',
};

// for IPP DE
const IPP_DE_LOOKUP = {
  '6502-03': UPPER_DE_SMARTWIRE_1,
  '6500-10': UPPER_DE_SMARTWIRE_1,
  '6502-04': LOWER_DE_SMARTWIRE_1,
  '6500-11': LOWER_DE_SMARTWIRE_1,
  '6500-12': UPPER_DE_SMARTWIRE_2,
  '6501-01': UPPER_DE_SMARTWIRE_2,
  '6500-13': LOWER_DE_SMARTWIRE_2,
  '6501-02': LOWER_DE_SMARTWIRE_2,
  '6500-20': LOWER_DE_SMARTWIRE_2A,
  '6501-07': LOWER_DE_SMARTWIRE_2A,
  '6400-05': UPPER_DE_IDB_TRAY,
  '6400-06': LOWER_DE_IDB_TRAY,
  '6401-02': UPPER_DE_IDB_TRAY,
  '6401-03': LOWER_DE_IDB_TRAY,
  '6120-04': STANDARD_BRACKET,
  '6120-02': MOLAR_BRACKET,
  '6200-05': LOWER_ANTERIOR_BRACKET,
  80010: UPPER_DE_SMARTWIRE_1,
  80009: LOWER_DE_SMARTWIRE_1,
  80012: UPPER_DE_SMARTWIRE_2,
  80011: LOWER_DE_SMARTWIRE_2,
  80014: UPPER_DE_SMARTWIRE_3,
  80013: LOWER_DE_SMARTWIRE_3,
  80016: UPPER_DE_IDB_TRAY,
  80015: LOWER_DE_IDB_TRAY,
  80031: UPPER_DE_SMARTWIRE_0,
  80032: LOWER_DE_SMARTWIRE_0,
  80025: 'Upper DE Single-Tooth IDB Tray',
  80026: 'Lower DE Single-Tooth IDB Tray',
};

// for BPP
const BPP_LOOKUP = {
  '6502-03': BPP_UPPER_SMARTWIRE_1_HISTORIC,
  '6500-10': BPP_UPPER_SMARTWIRE_1_HISTORIC,
  '6502-04': BPP_LOWER_SMARTWIRE_1_HISTORIC,
  '6500-11': BPP_LOWER_SMARTWIRE_1_HISTORIC,
  '6500-12': BPP_UPPER_SMARTWIRE_2_HISTORIC,
  '6501-01': BPP_UPPER_SMARTWIRE_2,
  '6500-13': BPP_LOWER_SMARTWIRE_2_HISTORIC,
  '6501-02': BPP_LOWER_SMARTWIRE_2,
  '6500-20': BPP_LOWER_SMARTWIRE_2A_HISTORIC,
  '6501-07': BPP_LOWER_SMARTWIRE_2A,
  '6400-05': UPPER_IDB_TRAY_PVS,
  '6400-06': LOWER_IDB_TRAY_PVS,
  '6401-02': UPPER_IDB_TRAY_THREEDP,
  '6401-03': LOWER_IDB_TRAY_THREEDP,
  '6120-04': STANDARD_BRACKET,
  '6120-02': MOLAR_BRACKET,
  '6200-05': LOWER_ANTERIOR_BRACKET,
  80028: UPPER_SMARTWIRE_0_GEN_2,
  80027: LOWER_SMARTWIRE_0_GEN_2,
  80002: UPPER_SMARTWIRE_1_GEN_2,
  80001: LOWER_SMARTWIRE_1_GEN_2,
  80004: UPPER_SMARTWIRE_2_GEN_2,
  80003: LOWER_SMARTWIRE_2_GEN_2,
  80006: UPPER_SMARTWIRE_3_GEN_2,
  80005: LOWER_SMARTWIRE_3_GEN_2,
  80008: UPPER_IDB_TRAY_GEN_2,
  80007: LOWER_IDB_TRAY_GEN_2,
  80025: 'Upper Single-Tooth IDB Tray',
  80026: 'Lower Single-Tooth IDB Tray',
};

// for BPP DE
const BPP_DE_LOOKUP = {
  '6502-03': BPP_UPPER_DE_SMARTWIRE_1_HISTORIC,
  '6500-10': BPP_UPPER_DE_SMARTWIRE_1_HISTORIC,
  '6502-04': BPP_LOWER_DE_SMARTWIRE_1_HISTORIC,
  '6500-11': BPP_LOWER_DE_SMARTWIRE_1_HISTORIC,
  '6500-12': BPP_UPPER_DE_SMARTWIRE_2_HISTORIC,
  '6501-01': BPP_UPPER_DE_SMARTWIRE_2,
  '6500-13': BPP_LOWER_DE_SMARTWIRE_2_HISTORIC,
  '6501-02': BPP_LOWER_DE_SMARTWIRE_2,
  '6500-20': BPP_LOWER_DE_SMARTWIRE_2A_HISTORIC,
  '6501-07': BPP_LOWER_DE_SMARTWIRE_2A,
  '6400-05': UPPER_DE_IDB_TRAY_PVS,
  '6400-06': LOWER_DE_IDB_TRAY_PVS,
  '6401-02': UPPER_DE_IDB_TRAY_THREEDP,
  '6401-03': LOWER_DE_IDB_TRAY_THREEDP,
  '6120-04': STANDARD_BRACKET,
  '6120-02': MOLAR_BRACKET,
  '6200-05': LOWER_ANTERIOR_BRACKET,
  80010: UPPER_DE_SMARTWIRE_1_GEN_2,
  80009: LOWER_DE_SMARTWIRE_1_GEN_2,
  80012: UPPER_DE_SMARTWIRE_2_GEN_2,
  80011: LOWER_DE_SMARTWIRE_2_GEN_2,
  80014: UPPER_DE_SMARTWIRE_3_GEN_2,
  80013: LOWER_DE_SMARTWIRE_3_GEN_2,
  80016: UPPER_DE_IDB_TRAY_GEN_2,
  80015: LOWER_DE_IDB_TRAY_GEN_2,
  80031: UPPER_DE_SMARTWIRE_0_GEN_2,
  80032: LOWER_DE_SMARTWIRE_0_GEN_2,
  80025: 'Upper DE Single-Tooth IDB Tray',
  80026: 'Lower DE Single-Tooth IDB Tray',
};

/**
 * Returns the corresponding item parts lookup dictionary
 * @param {string} case_id case id of item request
 * @param {boolean} isBPP whether BPP or not
 */
function getLookup(case_id, isBPP = false) {
  const isDE = case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);

  if (isBPP) return isDE ? BPP_DE_LOOKUP : BPP_LOOKUP;
  else return isDE ? IPP_DE_LOOKUP : IPP_LOOKUP;
}

const new_branding_words = (name) => {
  const gen_1_5_verbage = {
    // Job Titles
    RAE: 'Business Growth Consultant',
    'Regional Account Executive': 'Business Growth Consultant',
    'Insides Sales Representative ': 'Business Growth Specialist ',
    CST: 'Technology Integration Specialist',
    'Clinical Success Trainer': 'Technology Integration Specialist',
    'Sales and Customer Experience': 'Business Development & Practice Integration',
    CSS: 'InBrace Concierge',
    'Customer Support Specialist ': 'InBrace Concierge',
    'Clinical Advisor': 'Smile Design Advisor',
    CAD: 'Appliance Designer',
    Digiset: 'Smile Designer',
    // Statuses
    'CAD Process': 'Appliance Design Process',
    'CAD Revision': 'Appliance Design Revision',
    // filters
    'Upload Setup': 'Upload Smile Design',
    'Setup in Conversion': 'Smile Design in Conversion',
    'Release Setup': 'Release Smile Design',
    'Create Initial Setup': 'Create Initial Smile Design',
    'Internal Setup Review': 'Internal Smile Design Review',
    'Internal Setup Revision': 'Internal Smile Design Revision',
    'INBRACE Action Required': 'InBrace Action Required',
    'INBRACE Manufacturing Item Request': 'InBrace Fabricating Item Request',
    'INBRACE Manufactured Item Request': 'InBrace Fabricated Item Request',
    'INBRACE Review Request': 'InBrace Review Request',
    'INBRACE Reviewing Request': 'InBrace Reviewing Request',
    'INBRACE Updated Item Request': 'InBrace Updated Item Request',
    'INBRACE Approved Item Request': 'InBrace Approved Item Request',
    'Setup Ready for Release': 'Smile Design Ready for Release',
    'Revise Setup': 'Revise Smile Design',
    'Doctor Approve or Revise Setup': 'Doctor Review Smile Design',
    'Doctor Approve or Revise Smile Design': 'Doctor Review Smile Design',
    'Final Design Upload': 'Final Design Upload',
    'Final Design in Conversion': 'Final Design in Conversion',
    'Final Design Conversion Failed': 'Conversion Failed',
    'Final Design Ready for Release': 'Release Final Design',
    'Final Design Recipe': 'IFS Recipe',
    'Final Design Review': 'IFS Review',
  };

  const gen_2_0_verbiage = {
    'Upper DE Smartwire 1': 'DE Smartwire 1',
    'Upper DE Smartwire 2': 'DE Smartwire 2',
    'Upper DE Smartwire 3': 'DE Smartwire 3',
    'Lower DE Smartwire 1': 'DE Smartwire 1',
    'Lower DE Smartwire 2': 'DE Smartwire 2',
    'Lower DE Smartwire 3': 'DE Smartwire 3',
  };

  if (name in gen_2_0_verbiage) {
    return gen_2_0_verbiage[name];
  }
  if (name in gen_1_5_verbage) {
    return gen_1_5_verbage[name];
  }

  return name;
};

/**
 * Converts filter to new veribage
 * @function
 * @param {String} filter - Filter string to convert
 * @returns {String} Converted string
 */
const new_filter_words = (filter) => {
  const words = {
    CAD: 'Appliance Design',
    'CAD Revision': 'Appliance Revision',
    'Create Initial Setup': 'Create Initial Smile Design',
    'Doctor Approve or Revise Setup': 'Doctor Review Smile Design',
    'Doctor Approve or Revise Smile Design': 'Doctor Review Smile Design',
    'INBRACE Action Required': 'InBrace Action Required',
    'INBRACE Approved Item Request': 'InBrace Approved Item Request',
    'INBRACE Manufactured Item Request': 'InBrace Fabricated Item Request',
    'INBRACE Manufacturing Item Request': 'InBrace Fabricating Item Request',
    'INBRACE Review Request': 'InBrace Review Request',
    'INBRACE Reviewing Request': 'InBrace Reviewing Request',
    'INBRACE Updated Item Request': 'InBrace Updated Item Request',
    'Internal Setup Review': 'Internal Smile Design Review',
    'Internal Setup Revision': 'Internal Smile Design Revision',
    'Manufacture Case': 'Fabricate Case',
    'Quality Control Stage I': 'Stage I Review',
    'Release Setup': 'Release Smile Design',
    'Revise Setup': 'Revise Smile Design',
    'Setup in Conversion': 'Smile Design in Conversion',
    'Setup Ready for Release': 'Smile Design Ready for Release',
    'Upload Setup': 'Upload Smile Design',
    'Final Design Upload': 'Final Design Upload',
    'Final Design in Conversion': 'Final Design in Conversion',
    'Final Design Conversion Failed': 'Conversion Failed',
    'Final Design Ready for Release': 'Release Final Design',
    'Final Design Recipe': 'IFS Recipe',
    'Final Design Review': 'IFS Review',
  };

  if (filter in words) return words[filter];
  return filter;
};

export { new_branding_words, new_filter_words, getLookup };
