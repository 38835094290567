/**
 * File: modal.js - A component that contains the base modal for all modals in
 * this application
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import Axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal Components
import AccountActivation from './account_activation';
import AccountCreationType from './account_creation_type';
import ActiveCases from './active_cases';
import CaseFilterPreferences from './case_filter_preferences';
import CircleLoader from '../../components/loader/circle_loader';
import Checklist from './checklist';
import DisableAccount from './disable_account';
import DeleteProfilePicture from './delete_profile_picture';
import DraftDeleteProfilePicture from './draft_delete_profile_picture';
import EditPatient from './edit_patient';
import EditTXDEPlan from './edit_tx_de_plan';
import InvalidAddress from './invalid_address';
import Loader from '../loader/loader';
import Logs from './logs';
import Notes from './notes';
import MarkShipped from './mark_shipped';
import MissingTeethMeasurements from './missing_teeth_measurements';
import ProgressNotes from './progress_notes';
import ReactPlayer from 'react-player';
import RepositionProfilePicture from './reposition_profile_picture';
import DraftRepositionProfilePicture from './draft_reposition_profile_picture';
import ReferralCode from './referral_code_data';
import ValidateReferralCode from './validate_referral_code';
import ProgramAgreement from './program_agreement';
import ExpediteDetails from './expedite_details';
import MultipleCheckBoxes from './multiple_checkboxes';
import IprTeethChart from './ipr_teeth_chart';
import TeethChartWithCheckboxes from './teeth_chart_with_checkboxes';
import Status from './status';
import TeethChart from './teeth_chart/teeth_chart';
import Uploader from './uploader';
import DOMPurify from 'dompurify';
import CheckBox from '../checkbox/checkbox';
import SelectShippingAddress from './select_address';
import RichText from '../richtext/rich_text';
import TextBox from '../../doctor/components/inputs/text_box';
import ErrorMessage from '../../doctor/components/container/error_message';

// Internal Functions
import { userHasPermission } from '../../common/permission';
import { getShippingAddress, getShippingAddressName, getMinDate } from '../../common/helpers';
import { supportsPDFs } from '../../common/browser';
import { modalClose, textFieldCheck, removeCaseIdInitialNumber, removeEmoji, isDateOnOrAfterMinDate, isDateValid } from '../../common/functions';
import { isAfterSmileDesignApproval, isBeforeFabricatingStage } from '../../common/case/case_status';
import { closePDFModalOnRedirect } from '../../common/pdf';
import './ipp_modal.scss';
import './bpp_modal.scss';

// Redux
import {
  getApplianceDesignTargetDateInput,
  getPreferredShippingInput,
  getSmileDesignTargetDateInput,
} from '../../redux/reducers/bpp/shipping/expedite_details';
import { setApplianceDesignTargetDateInput } from '../../redux/actions/bpp/shipping/expedite_details';
import ClipboardCopy from '../clipboard/clipboard';
import PDFViewerInterface from '../pdf-viewer/pdf_viewer';
import Moment from 'moment';

/**
 * Used to show all different modals for the portal both bpp side and ipp side.
 *
 * @component
 * @alias Modal
 * @category Common
 */
class Modal extends Component {
  constructor(props) {
    super(props);

    const modal_type = this.props.preset;

    //--------------------------------------------------------------------------
    // Do not modify, only add
    let modal_preset = {
      address: false,
      address_list: [],
      account_activation: false,
      account_creation_type: false,
      active_cases: false,
      after_confirm: false,
      after_confirm_active: false,
      after_confirm_text: '',
      approve_shipping: false,
      awb: false,
      backdrop: false,
      backdrop_action: '',
      backdrop_action_available: false,
      case_filter_preferences: false,
      clinical_review: false,
      close_btn: true,
      close_btn_pos: '',
      close_btn_text: 'Close',
      confirm_btn: false,
      confirm_btn_clicked: false,
      confirm_btn_pos: '',
      confirm_btn_text: '',
      delete_profile_picture: false,
      draft_delete_profile_picture: false,
      enable_8: false,
      select_address: true,
      selections_show: false,
      selections: [],
      selections_case: false,
      datearea: false,
      datearea_text: '',
      sub_header_text: '',
      datearea_value: '',
      datearea_warning_active: false,
      datearea_warning_text: '',
      disable_account: false,
      download_btn: false,
      dropdown: false,
      dropdown_doctor_warning_active: false,
      edit_patient: false,
      edit_patient_warning_active: false,
      edit_patient_warning_text: '',
      external_target_date: false,
      edit_tx_plan: false,
      expedite_details: false,
      expedite_details_date_error: false,
      expedite_details_no_update_error: false,
      expedite_type: '',
      footer: true,
      header: true,
      header_text: '',
      ifu: false,
      invalid_address: false,
      credit_hold: false,
      ip_review: false,
      loading: true,
      log: false,
      log_type: '',
      log_value: [],
      mark_shipped: false,
      message: true,
      message_text: '',
      notes: false,
      production_tx_guide_pdf_loaded: false,
      progress_notes: false,
      pdf_viewer: false,
      print_btn: false,
      profile_picture: false,
      qc_revision: false,
      reload_page: false,
      remove_btn_text: '',
      draft_reposition_profile_picture: false,
      selected_reason: '',
      status: false,
      teeth_chart: false,
      textarea: false,
      textarea_placeholder: '',
      textarea_required: false,
      textarea_value: '',
      textarea_doctor_note_value: '',
      textarea_warning_active: false,
      textarea_doctor_note_warning_active: false,
      textarea_warning_text: '',
      textarea_maxlength_active: false,
      textarea_doctor_note_maxlength_active: false,
      textarea_maxlength_message: 'The character limit has been reached.',
      theme: 'ipp',
      erp: false,
      upload: false,
      upload_in_progress: false,
      upload_pdf: false,
      upload_loaded: false,
      video_viewer: false,
      video_url: '',
      video_loaded_url: null,
      x_btn: true,
      disable_button: false,
      checked: true,
      validate_referral_code: false,
      referral_code: false,
      submitting: false,
      program_agreement: false,
      multiple_checkboxex: false,
      ipr_chart: false,
      ipr_note: '',
      file_upload_area: false,
      popout_error_message: false,
      teeth_chart_with_checkboxes: false,
      image_load_complete: false,
    };
    //--------------------------------------------------------------------------
    switch (modal_type) {
      case 'standard-confirmation':
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        modal_preset.clost_btn_text = 'Cancel';
        break;
      case 'account-activation':
        modal_preset.account_activation = true;
        modal_preset.erp = true;
        break;
      case 'account-creation-type':
        modal_preset.account_creation_type = true;
        modal_preset.header = false;
        modal_preset.footer = false;
        break;
      case 'action':
        modal_preset.close_btn = false;
        modal_preset.confirm_btn = true;
        break;
      case 'active-cases':
        modal_preset.active_cases = true;
        break;
      case 'approve-shipping':
        modal_preset.approve_shipping = true;
        break;
      case 'awb-missing-teeth':
        modal_preset.awb = true;
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        break;
      case 'cancellation':
        modal_preset.address = true;
        modal_preset.confirm_btn = true;
        modal_preset.textarea = true;
        break;
      case 'case-filter-preferences':
        modal_preset.case_filter_preferences = true;
        modal_preset.confirm_btn = true;
        break;
      case 'checklist':
        modal_preset.checklist = true;
        modal_preset.confirm_btn = true;
        break;
      case 'clinical-review':
        modal_preset.confirm_btn = true;
        modal_preset.clinical_review = true;
        break;
      case 'confirmation':
        modal_preset.header = false;
        modal_preset.footer = false;
        break;
      case 'date':
        modal_preset.confirm_btn = true;
        modal_preset.datearea = true;
        break;
      case 'delete-profile-picture':
        modal_preset.delete_profile_picture = true;
        modal_preset.confirm_btn = true;
        break;
      case 'decision':
        modal_preset.confirm_btn = true;
        break;
      case 'decision-rework':
        modal_preset.confirm_btn = true;
        modal_preset.selections_show = true;
        modal_preset.selections = _.cloneDeep(this.props.selections);
        break;
      case 'decision-rework-case':
        modal_preset.confirm_btn = true;
        modal_preset.selections_show = true;
        modal_preset.selections_case = true;
        break;
      case 'decision-dialog':
        modal_preset.confirm_btn = true;
        modal_preset.textarea = true;
        modal_preset.textarea_required = true;
        break;
      case 'decision-dialog-hold-cancel':
        modal_preset.confirm_btn = true;
        modal_preset.textarea = true;
        modal_preset.dropdown_required = true;
        modal_preset.dropdown = true;
        break;
      case 'decision-ifu':
        modal_preset.confirm_btn = true;
        modal_preset.ifu = true;
        break;
      case 'decision-referralcode':
        modal_preset.confirm_btn = true;
        modal_preset.referral_code = true;
        break;
      case 'decision-return-stage-one':
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        break;
      case 'decision-wire-selection':
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        break;
      case 'decision-validate-referral-code':
        modal_preset.confirm_btn = true;
        modal_preset.validate_referral_code = true;
        break;
      case 'decision-credit-hold':
        modal_preset.confirm_btn = true;
        modal_preset.credit_hold = true;
        break;
      case 'disable-account':
        modal_preset.confirm_btn = this.props.confirm_btn || true;
        modal_preset.disable_account = true;
        break;
      case 'draft-delete-profile-picture':
        modal_preset.draft_delete_profile_picture = true;
        modal_preset.confirm_btn = true;
        break;
      case 'draft-reposition-profile-picture':
        modal_preset.draft_reposition_profile_picture = true;
        modal_preset.confirm_btn = true;
        break;
      case 'edit-patient':
        modal_preset.edit_patient = true;
        modal_preset.confirm_btn = true;
        break;
      case 'edit-tx-plan':
        modal_preset.edit_tx_plan = true;
        modal_preset.confirm_btn = true;
        break;
      case 'invalid-address':
        modal_preset.invalid_address = true;
        modal_preset.confirm_btn = true;
        break;
      case 'log':
        modal_preset.backdrop_action = 'close';
        modal_preset.close_btn_pos = 'right';
        modal_preset.log = true;
        break;
      case 'mark-shipped':
        modal_preset.mark_shipped = true;
        break;
      case 'message':
        // same as modal_preset
        break;
      case 'notes':
        modal_preset.close_btn_pos = 'right';
        modal_preset.notes = true;
        break;
      case 'progress-notes':
        modal_preset.close_btn_pos = 'right';
        modal_preset.progress_notes = true;
        break;
      case 'qc-revision':
        modal_preset.qc_revision = true;
        modal_preset.confirm_btn = true;
        modal_preset.textarea = true;
        modal_preset.textarea_required = true;
        break;
      case 'reposition-profile-picture':
        modal_preset.reposition_profile_picture = true;
        modal_preset.confirm_btn = true;
        break;
      case 'expedite-details':
        modal_preset.expedite_details = true;
        break;
      case 'status':
        modal_preset.backdrop_action = 'close';
        modal_preset.close_btn_pos = 'right';
        modal_preset.status = true;
        break;
      case 'teeth-chart-8':
        modal_preset.enable_8 = true;
      // intentional fall-thru
      // eslint-disable-next-line
      case 'teeth-chart':
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        modal_preset.teeth_chart = true;
        break;

      case 'update-address':
        modal_preset.confirm_btn = true;
        modal_preset.close_btn = true;
        modal_preset.select_address = true;
        break;
      case 'erp':
        modal_preset.erp = true;
        break;
      case 'upload-pdf':
        modal_preset.upload_pdf = true;
      // intentional fall-thru
      // eslint-disable-next-line
      case 'upload':
        modal_preset.close_btn_pos = 'right';
        modal_preset.upload = true;
        break;
      case 'pdf_viewer':
        modal_preset.backdrop = true;
        modal_preset.close_btn_pos = 'right';
        modal_preset.loading = false;
        modal_preset.pdf_viewer = true;
        break;
      case 'profile-picture':
        modal_preset.profile_picture = true;
        break;
      case 'video_viewer':
        modal_preset.backdrop = true;
        modal_preset.close_btn_pos = 'right';
        modal_preset.loading = true;
        modal_preset.video_viewer = true;
        // code block
        break;
      case 'ip_review':
        modal_preset.confirm_btn = true;
        modal_preset.after_confirm = true;
        modal_preset.after_confirm_text = 'Sending file to wirebending machine...';
        modal_preset.ip_review = true;
        break;
      case 'submitting':
        modal_preset.close_btn = false;
        modal_preset.loading = false;
        modal_preset.submitting = true;
        modal_preset.x_btn = false;
        break;
      case 'program_agreement':
        modal_preset.confirm_btn = true;
        modal_preset.program_agreement = true;
        break;
      case 'navigator_initial_bonding':
        modal_preset.confirm_btn = true;
        modal_preset.datearea = true;
        break;
      case 'multiple_checkboxex':
        modal_preset.multiple_checkboxex = true;
        modal_preset.confirm_btn = true;
        break;
      case 'radio_btns':
        modal_preset.confirm_btn = true;
        break;
      case 'ipr_chart':
        modal_preset.ipr_chart = true;
        modal_preset.ipr_note = this.props.ipr_note;
        modal_preset.confirm_btn = true;
        break;
      case 'navigator_debonding':
        modal_preset.file_upload_area = true;
        modal_preset.datearea = true;
        modal_preset.confirm_btn = true;
        break;
      case 'teeth_chart_with_checkboxes':
        modal_preset.teeth_chart_with_checkboxes = true;
        modal_preset.confirm_btn = true;
        break;
      case 'external_target_date':
        modal_preset.confirm_btn = true;
        modal_preset.external_target_date = true;
        break;
      case 'decision-prevent-confirm-disable':
        modal_preset.confirm_btn = true;
        modal_preset.preventConfirmDisable = true;
        break;
      case 'international-shipping':
        modal_preset.international_shipping = true;

      default:
      // code block
    }
    //--------------------------------------------------------------------------

    this.state = modal_preset;
  }

  componentDidMount() {
    if (window.$('.select-address-modal')) {
      window.$('.select-address-modal').selectpicker();
    }
    let new_state = { ...this.state };
    const that = this;

    // Override any state with props passed in
    for (let value in this.state) {
      new_state[value] = this.props[value] !== undefined ? this.props[value] : this.state[value];
    }

    if (this.props.upload_content && this.props.upload_content.length > 0) {
      new_state.upload_loaded = true;
    }

    // Log values
    if ((this.state.log || this.state.status) && this.props.case_id && this.props.selected_tab === 'case') {
      const log_urls = {
        wire_selection: `/apiv3/statuslogs/wireselection/${this.props.case_id}`,
        wire_manufacturing: `/apiv3/statuslogs/wiremanufacturing/${this.props.case_id}`,
        production: `/apiv3/statuslogs/production/${this.props.case_id}`,
      };
      const log_url = this.props.log_type in log_urls ? log_urls[this.props.log_type] : `/api/status/?case_id=${this.props.case_id}&log=${this.state.log}`;

      Axios.get(log_url).then(function (res) {
        if (res && res.data && res.data.data) {
          that.setState({
            log_value: res.data.data,
            loading: false,
          });
        }
      });
    } else if (this.state.status && this.props.item_request_id && this.props.item_request_status && this.props.selected_tab === 'item_request') {
      let item_request_status = [];
      if (this.props.item_request_status && this.props.item_request_id) {
        this.props.item_request_status.forEach((status) => {
          if (status.ireq_id === this.props.item_request_id) {
            item_request_status.push(status);
          }
        });
      }

      new_state.log_value = item_request_status;
      new_state.loading = false;
    } else if (this.state.status && this.props.smile_id) {
      Axios.get(`/apiv3/smilesimulation/${this.props.smile_id}/status_history`).then(function (res) {
        if (res && res.data && res.data.status_history) {
          that.setState({
            log_value: res.data.status_history,
            loading: false,
          });
        }
      });
    }

    if (this.state.video_viewer && this.props.video_url) {
      Axios({
        url: '/api/link/?file=/' + that.props.video_url,
        method: 'GET',
      }).then((response) => {
        if (that.isDead) return;
        that.setState({
          loading: false,
          video_loaded_url: response.data,
        });
      });
    }

    if (this.state.video_viewer && this.props.kb_video_url) {
      Axios({
        url: '/apiv3/knowledgebase?file=' + that.props.kb_video_url,
        method: 'GET',
      }).then((response) => {
        if (that.isDead) return;
        this.setState({ loading: false, video_loaded_url: response.data });
      });
    }

    // Set new state
    this.setState(new_state);

    setTimeout(function () {
      this.$('.base-modal').modal('show');
    }, 0);

    // Delay backdrop closing upon double clicking
    setTimeout(function () {
      that.setState({
        backdrop_action_available: true,
      });
    }, 1000);
  }

  componentDidUpdate() {
    if (window.$('.select-address-modal')) {
      window.$('.select-address-modal').selectpicker();
    }
    if (this.state.edit_patient && this.state.edit_patient_warning_active) {
      if (this.hasEmptyPatientField()) {
        if (this.props.patient_firstname === '') {
          document.querySelector('#patientFirstName').classList.add('warning-border');
          document.querySelector('#patientFirstNameLabel').classList.add('warning-text');
        } else {
          document.querySelector('#patientFirstName').classList.remove('warning-border');
          document.querySelector('#patientFirstNameLabel').classList.remove('warning-text');
        }

        if (this.props.patient_lastname === '') {
          document.querySelector('#patientLastName').classList.add('warning-border');
          document.querySelector('#patientLastNameLabel').classList.add('warning-text');
        } else {
          document.querySelector('#patientLastName').classList.remove('warning-border');
          document.querySelector('#patientLastNameLabel').classList.remove('warning-text');
        }

        if (this.props.patient_dob === '') {
          document.querySelector('#patientDOB').classList.add('warning-border');
          document.querySelector('#patientDOBLabel').classList.add('warning-text');
        } else {
          document.querySelector('#patientDOB').classList.remove('warning-border');
          document.querySelector('#patientDOBLabel').classList.remove('warning-text');
        }

        if (this.props.patient_sex === '') {
          document.querySelector('#patientSex').classList.add('warning-text');
        } else {
          document.querySelector('#patientSex').classList.remove('warning-text');
        }
      } else {
        this.setState({ edit_patient_warning_active: false });
        document.querySelector('#patientFirstName').classList.remove('warning-border');
        document.querySelector('#patientFirstNameLabel').classList.remove('warning-text');
        document.querySelector('#patientLastName').classList.remove('warning-border');
        document.querySelector('#patientLastNameLabel').classList.remove('warning-text');
        document.querySelector('#patientDOB').classList.remove('warning-border');
        document.querySelector('#patientDOBLabel').classList.remove('warning-text');
        document.querySelector('#patientSex').classList.remove('warning-text');
      }
      this.scrollToErrorMessage();
    }
  }

  componentWillUnmount() {
    document.body.className = document.body.className.replace('modal-open', '');
    modalClose('baseModal');
  }

  hasEmptyPatientField = () => {
    return (
      this.props.patient_firstname === '' ||
      this.props.patient_lastname === '' ||
      this.props.patient_sex === '' ||
      this.props.patient_dob === '' ||
      this.checkAttributionError()
    );
  };

  /**
   * Determines if there is a click outside of the modal and close it if true
   * @function
   * @param {event} e - Button click event
   */
  onBackdropClick = (e) => {
    const backdrop_action = this.state.backdrop_action;
    const client_x = e.clientX;
    const client_y = e.clientY;
    const element = document.getElementsByClassName('modal-dialog');
    const clicked_backdrop =
      client_x < element[0].offsetLeft ||
      client_y < element[0].offsetTop ||
      client_x > element[0].offsetLeft + element[0].scrollWidth ||
      client_y > element[0].offsetTop + element[0].scrollHeight;
    const close_btn = document.getElementById('modal_close_btn');

    if (clicked_backdrop && client_x !== 0 && client_y !== 0 && this.state.backdrop_action_available) {
      switch (backdrop_action) {
        case 'close':
          // code block
          if (close_btn) {
            close_btn.click();
          }
          break;

        default:
      }
    }
  };

  /** Handles the event for when the confirm button is clicked
   *
   * @function
   * @param {event} e - Button click event
   */
  onConfirmButtonClick = (e) => {
    const text = this.state.textarea_value.trim();
    const text_doctor_note = this.state.textarea_doctor_note_value.trim();
    const date = this.state.datearea_value;
    const reason = this.state.selected_reason;
    const attribution_error = this.checkAttributionError();

    const isValidDate = this.props.isValidDate ? this.props.isValidDate(date) : this.state.datearea && date === null ? false : true;

    if (this.state.expedite_details) {
      this.setState({ expedite_details_no_update_error: false, expedite_details_date_error: false });
    }
    if (this.state.mark_shipped) {
      this.setState({ datearea_warning_active: false });
    }

    if (this.state.dropdown && reason === '' && this.state.dropdown_required) {
      this.setState({ textarea_warning_active: true });
      return false;
    } else if (
      this.state.dropdown &&
      text_doctor_note === '' &&
      this.state.selected_reason === 'Other' &&
      this.state.header_text !== 'Prospect Lost' &&
      this.state.header_text !== 'Hold Prospect'
    ) {
      this.setState({ textarea_doctor_note_warning_active: true });
      return false;
    } else if (
      (this.state.dropdown &&
        text === '' &&
        reason === 'Other' &&
        (this.state.header_text === 'Prospect Lost' || this.state.header_text === 'Hold Prospect')) ||
      (reason === '' && (this.state.header_text === 'Prospect Lost' || this.state.header_text === 'Hold Prospect'))
    ) {
      this.setState({ textarea_doctor_note_warning_active: true });
      if (reason === '' && (this.state.header_text === 'Prospect Lost' || this.state.header_text === 'Hold Prospect')) {
        this.setState({ dropdown_doctor_warning_active: true });
      }
      return false;
    } else if (this.props.show_expedite_request && !this.props.expedite_note) {
      this.setState({ textarea_warning_active: true });
      return false;
    } else if (this.props.show_expedite_approval || this.props.show_expedite_update) {
      if (this.props.show_expedite_update && this.hasExpediteNoUpdatesMadeError()) {
        this.setState({ expedite_details_no_update_error: true });
        return false;
      } else if (this.props.show_expedite_approval) {
        const expedite_details_missing_input_error =
          !this.props.expedite_target_ship_date ||
          !this.props.preferred_shipping_input ||
          (!this.props.id.includes('-IR') && isBeforeFabricatingStage(this.props.case_status) && !this.props.appliance_design_target_date_input);

        if (expedite_details_missing_input_error) {
          this.setState({ textarea_warning_active: true });
          return false;
        }
      }

      //TODO:
      // This whole validation should be refactored
      // there are validations duplicated on the form and on this confirm function
      // We should probably break these validations into a validation component and use it instead
      // Or we should integrate the "confirm" validation into the form itself
      const is_item_request = this.props.id.includes('-IR');
      const is_before_fabricating = isBeforeFabricatingStage(this.props.case_status);
      const expedite_details_date_error =
        !is_item_request && is_before_fabricating && this.props.appliance_design_target_date_input > this.props.expedite_target_ship_date;

      const today = Moment().format('YYYY-MM-DD');
      let invalid_appliance_design_target_date = !isDateOnOrAfterMinDate(this.props.appliance_design_target_date_input, today);
      let invalid_expedite_target_ship_date = !isDateValid(this.props.expedite_target_ship_date);
      if (!is_before_fabricating) {
        // if the case is after the fabricating stage it means that the
        // appliance design target date is valid, so we can override the check and set invalid as false
        invalid_appliance_design_target_date = false;

        // also if we're after fabricating, the expedite target ship date shouldn't be before the current date
        invalid_expedite_target_ship_date = !isDateOnOrAfterMinDate(this.props.expedite_target_ship_date, today);
      }
      if (is_item_request) {
        invalid_appliance_design_target_date = false;
      }
      const expedite_details_invalid_date = invalid_appliance_design_target_date || invalid_expedite_target_ship_date;

      if (expedite_details_date_error || expedite_details_invalid_date) {
        this.setState({ expedite_details_date_error: true });
        return false;
      }
    } else if (text === '' && this.state.textarea && this.state.textarea_required) {
      this.setState({ textarea_warning_active: true });
      return false;
    } else if (!isValidDate) {
      this.setState({ datearea_warning_active: true });
      return false;
    } else if (attribution_error) {
      this.setState({ edit_patient_warning_active: true });
      return false;
    }

    if (this.state.expedite_details) {
      this.setState({
        textarea_maxlength_active: false,
      });
    }

    if (!this.state.confirm_btn_clicked) {
      if (
        !this.state.expedite_details &&
        !this.props.show_expedite_request &&
        !this.state.awb &&
        !this.state.disable_account &&
        !this.state.edit_tx_plan &&
        !this.state.mark_shipped &&
        !this.state.enable_8 &&
        !this.state.edit_patient &&
        !this.state.external_target_date &&
        !this.state.preventConfirmDisable
      ) {
        this.setState({
          confirm_btn_clicked: true,
          after_confirm_active: this.state.after_confirm,
        });
      }
      this.props.onConfirmButtonClick(e);
    }
  };

  /**
   * Check if has attribution error
   *
   * @function
   * @returns {Boolean} True if has attribution error
   */
  checkAttributionError = () => {
    const attributions = this.props.checkAttributionError ? this.props.checkAttributionError(this.props.attributions) : this.props.attributions;
    if (attributions && attributions.length !== 0) {
      for (var i = 0; i < attributions.length; i++) {
        if (attributions[i].attributions_error || attributions[i].attribution_other_error) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Handles the event for when the date is changed
   *
   * @function
   * @param {event} e - Button click event
   */
  onDateAreaChange = (e) => {
    const date = e.target.value;

    this.setState({
      datearea_warning_active: false,
      datearea_value: date,
    });

    this.props.onDateAreaChange(e);
  };

  onTextAreaKeyDown = (e, text_area_type) => {
    const text = textFieldCheck(e.target.value);

    if (text.length >= e.target.maxLength) {
      if (text_area_type === 'doctor_note') {
        this.setState({
          textarea_doctor_note_maxlength_active: true,
        });
      } else {
        this.setState({
          textarea_maxlength_active: true,
        });
      }
    }
  };

  onTextAreaBlur = () => {
    this.setState({
      textarea_doctor_note_maxlength_active: false,
      textarea_maxlength_active: false,
    });
  };

  /**
   * Handles the event for when the user types something in the doctor note textbox
   *
   * @function
   * @param {event} e - Button click event
   */
  onTextAreaDoctorNoteChange = (e) => {
    const text = removeEmoji(textFieldCheck(e.target.value));

    if (text.length >= e.target.maxLength) {
      this.setState({ textarea_doctor_note_maxlength_active: true, textarea_doctor_note_value: removeEmoji(text) });
    } else {
      this.setState({
        textarea_doctor_note_warning_active: false,
        textarea_doctor_note_maxlength_active: false,
        textarea_maxlength_active: false,
        textarea_doctor_note_value: removeEmoji(text),
      });
    }

    this.props.onTextAreaDoctorNoteChange(e);
  };

  /**
   * Handles the event for when the user types something into the text box
   *
   * Determines and sets warning messages
   * @function
   * @param {event} e - Button click event
   */
  onTextAreaChange = (e) => {
    const text = removeEmoji(textFieldCheck(e.target.value));
    if (text.length >= e.target.maxLength) {
      this.setState({ textarea_maxlength_active: true, textarea_value: removeEmoji(text), textarea_warning_active: false });
    } else {
      if (!this.state.dropdown) {
        this.setState({
          textarea_warning_active: false,
        });
      }
      this.setState({
        textarea_maxlength_active: false,
        textarea_doctor_note_warning_active: false,
        textarea_value: removeEmoji(text),
      });
    }

    this.props.onTextAreaChange(e);
  };

  /**
   * Handles the event for when a user selects an option from the dropdown
   *
   * Resets all of the warning states
   * @function
   * @param {event} e - Button click event
   */
  onDropdownChange = (e) => {
    this.setState({
      selected_reason: e.target.value,
      textarea_warning_active: false,
      textarea_maxlength_active: false,
      textarea_doctor_note_warning_active: false,
      textarea_doctor_note_maxlength_active: false,
      dropdown_doctor_warning_active: false,
    });

    this.props.onDropdownChange(e);
  };

  /**
   * Handles the event for when the target ship date is changed during expedited process
   *
   * Resets warning states
   * @function
   * @param {event} e - Button click event
   */
  onExpediteTargetShipDateChange = (e) => {
    this.setState({
      textarea_warning_active: !isDateOnOrAfterMinDate(e.target.value, e.target.min),
      expedite_details_date_error: false,
    });
    this.props.onExpediteTargetShipDateChange(e);
  };
  /**
   * Handles the event for when the AD target ship date is changed during expedited process
   *
   * Resets warning states
   * @function
   * @param {event} e - Button click event
   */
  setApplianceDesignTargetDateInput = (e) => {
    this.setState({
      textarea_warning_active: !isDateOnOrAfterMinDate(e.target.value, e.target.min),
      expedite_details_date_error: false,
    });
    this.props.setApplianceDesignTargetDateInput(e.target.value);
  };

  isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }

    return false;
  }

  /**
   * Handles the event for when the local selection is changed
   *
   * @function
   * @param {event} e - Button click event
   */
  onLocalSelectionChangeRework = (event) => {
    let selections = this.state.selections;
    selections.forEach(function (element) {
      if (element.parts_id === parseInt(event.target.id)) {
        element.state = !element.state;
      }
    });
    this.setState({ selections: selections });
    this.props.setSelections(selections);
  };

  /**
   * Handles the event for referral opt in email change
   *
   * @function
   * @param {event} e - email input  event
   */
  onReferralEmailChange = (event) => {
    this.setState({
      confirm_btn_clicked: false,
    });
    this.props.handleEmailChanges(event);
  };

  setUploadInProgress = (in_progress) => {
    this.setState({
      upload_in_progress: in_progress,
    });
  };

  /**
   * Show Pop out warning box
   * @function
   */
  showPopOutWarning = () => {
    this.setState({
      popout_error_message: true,
    });
  };

  /**
   * Hide Pop out warning box
   * @function
   */
  closePopOutWarning = () => {
    this.setState({
      popout_error_message: false,
    });
  };

  /**
   * Close the pdf_view modal after browser redirect because of pdf not supported
   * so that when click back, does not show the empty modal
   *
   * @function
   */
  scrollToErrorMessage = () => {
    var element = document.getElementsByClassName('modal-content')[0];
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  };

  /**
   * Determines if there are any updates made to expedite update modal
   *
   * @function
   * @returns {boolean} - True or false
   */
  hasExpediteNoUpdatesMadeError = () => {
    const is_case = this.props.expedite_type === 'Case';
    return (
      this.props.expedite_target_ship_date === this.props.initial_expedite_target_ship_date &&
      this.props.expedite_ship_ind === this.props.initial_expedite_ship_ind &&
      this.props.expedite_additional_note === this.props.initial_expedite_additional_note &&
      this.props.initial_preferred_shipping_input === this.props.preferred_shipping_input &&
      ((is_case &&
        this.props.edit_address_id === this.props.selected_address_id &&
        this.props.initial_smile_design_target_date === this.props.smile_design_target_date_input &&
        this.props.initial_appliance_design_target_date === this.props.appliance_design_target_date_input) ||
        (!is_case && this.props.edit_address_id === this.props.ir_selected_address_id))
    );
  };

  /**
   * Handle checkbox click force page reload
   * @param {string} checkboxId - Id of checkbox clicked
   * @function
   */
  onSelectInputChange = (checkboxId) => {
    this.setState({ reload_page: !this.state.reload_page });

    this.props.onSelectionChange(checkboxId);
  };

  /**
   * Handle text change force page reload
   * @param {string} checkboxId - Id of checkbox clicked
   * @function
   */
  onTextChange = (e) => {
    let note = removeEmoji(e.target.value);
    this.setState({ ipr_note: note });

    this.props.onTextChange(note);
  };

  /**
   * Handle btn double click
   * @param {string} e - Click event
   */
  onDoubleClick = (e) => {
    e.preventDefault();
    this.props.onDoubleClick(e);
  };

  /**
   * Displays multiple radio btn from props object
   * @return {JSX} Radio btns and labels
   */
  getRadioBtns = () => {
    return this.props.radio_btn_options.map((radio_btn) => (
      <div className="completion_switch_radio_div" key={radio_btn.id}>
        <input
          type="radio"
          id={radio_btn.id}
          name={radio_btn.description}
          className="radio_btns"
          disabled={this.props.disabledEdit}
          onChange={() => {
            this.onSelectInputChange(radio_btn.id);
          }}
          checked={radio_btn.state}
        ></input>
        <label htmlFor={radio_btn.description}>{radio_btn.description}</label>
      </div>
    ));
  };

  getPDFFileURL() {
    const pdf_file = this.props.pdf_url;
    return pdf_file ? { url: '/media/pdf/', params: { file: pdf_file } } : {};
  }

  getUploadPDFURL() {
    const upload_data = this.state.upload_pdf && this.props.upload_content?.[0]?.upload_data;
    return upload_data ? { url: '/media/pdf/', params: { file: upload_data, date: new Date() } } : {};
  }

  getIRInvoicePDFURL() {
    const has_invoice_info = this.props.ir_invoice_type && this.props.ireq_id;
    return has_invoice_info ? { url: `/apiV2/irinvoicedownload/${this.props.ireq_id}/${this.props.ir_invoice_type}`, params: { date: new Date() } } : {};
  }

  getIRLabelPDFURL() {
    const has_ir_label_info = this.props.pdf_type === 'ir_label' && this.props.ireq_id;
    return has_ir_label_info ? { url: `/apiV2/cadaction/${this.props.ireq_id}/generate_label_ir/get_label` } : {};
  }

  getPRPDFURL() {
    const pr_id = this.props.progress_id;
    return pr_id ? { url: `/apiV2/prcsqdownload/${this.props.progress_id}` } : {};
  }

  getIPRPDFURL() {
    const is_ipr = this.props.pdf_type === 'ipr' && this.props.case_id;
    return is_ipr ? { url: `/apiV2/case_ipr_download/${this.props.case_id}/${this.props.iprIndex}` } : {};
  }

  getKnowledgeBasePDFURL() {
    const is_knowledgebase = this.props.kb_pdf_url;
    return is_knowledgebase ? { url: '/apiv3/knowledgebase', params: { file: this.props.kb_pdf_url, type: 'pdf' } } : {};
  }

  getPdfURL() {
    const is_pdf_preset = this.state.pdf_viewer || this.state.upload_pdf;
    if (is_pdf_preset) {
      const pdf_urls = [
        this.getUploadPDFURL(),
        this.getIRInvoicePDFURL(),
        this.getIRLabelPDFURL(),
        this.getPRPDFURL(),
        this.getIPRPDFURL(),
        this.getKnowledgeBasePDFURL(),
        this.getPDFFileURL(),
      ];

      const obj = pdf_urls.find((obj) => obj && obj.url);
      if (obj && obj.url) {
        const date = this.props.pdf_date ?? obj?.params?.date;
        const datetime = new Date(date).getTime();
        const params = { ...obj.params, date: isNaN(datetime) ? '' : datetime };
        const param_string = new URLSearchParams(params).toString();
        return `${obj.url}?${param_string}`;
      }
    }
    return '';
  }

  getPDFViewer() {
    const pdf_url = this.getPdfURL();

    if (pdf_url) {
      return <PDFViewerInterface fileUrl={pdf_url} filename={this.props.original_filename} watermark={this.props.watermark} />;
    }
  }
  /**
   * Handles image load complet
   * @param {*} loaded
   */
  handleImageLoadComplete = (loaded) => {
    this.setState({ image_load_complete: loaded });
  };

  render() {
    const modal_title =
      this.state.shipping_details || this.state.profile_picture
        ? this.props.header_text
        : this.state.header_text + (this.props.header_text_paitent_name ? ' - ' + this.props.header_text_paitent_name : '');
    return (
      <div
        className={this.props.theme === 'bpp' ? `modal fade base-modal bpp-modal ${this.props.className}` : `modal fade base-modal ${this.props.className}`}
        id="baseModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="baseModal"
        data-backdrop={this.state.backdrop ? 'static' : 'false'}
        data-keyboard="false"
        onClick={this.onBackdropClick}
      >
        <div className={this.props.modal_size_class ? `${this.props.modal_size_class} modal-dialog` : 'modal-dialog'} role="document">
          <div className={this.props.modal_class ? `${this.props.modal_class} modal-content` : 'modal-content'}>
            {this.state.header || (this.state.profile_picture && this.props.header) ? (
              <div className="modal-header">
                {this.state.x_btn && !this.state.after_confirm_active ? (
                  <React.Fragment>
                    {this.props.in_progress || this.props.save_tx_plan_changes ? null : (
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.props.onCloseButtonClick}
                        onDoubleClick={this.onDoubleClick}
                        disabled={(this.state.upload_pdf || this.state.upload || this.state.file_upload_area) && this.state.upload_in_progress}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    )}
                  </React.Fragment>
                ) : null}
                <h4 className="modal-title fs-exclude">
                  {modal_title}
                  {this.props.theme === 'bpp' && <ClipboardCopy copy_text={modal_title} theme="light"></ClipboardCopy>}
                </h4>

                <p>{this.state.sub_header_text}</p>
              </div>
            ) : null}
            <div className={this.props.modal_body_class ? `${this.props.modal_body_class} modal-body` : 'modal-body center-text'}>
              {this.state.message && !this.state.after_confirm_active ? (
                <div
                  className={
                    (this.props.message_text_class ? this.props.message_text_class : '') +
                    'p-slim ' +
                    (this.state.dropdown ? 'modal-message-text' : 'grey-text')
                  }
                >
                  {this.props.in_progress ? (
                    <div className="center-text">
                      <CircleLoader />
                      <p className={this.state.profile_picture ? 'no-margin-bottom' : ''}>
                        {this.props.in_progress_text ? this.props.in_progress_text : 'Loading...'}
                      </p>
                    </div>
                  ) : this.isHTML(this.state.approve_shipping ? this.props.message_text : this.state.message_text) ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(this.state.message_text, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                      }}
                    />
                  ) : this.state.profile_picture || this.state.approve_shipping || this.state.erp ? (
                    this.props.message_text
                  ) : (
                    this.state.message_text
                  )}
                </div>
              ) : this.state.ip_review && this.props.approveFailure ? (
                <div className={`${this.props.message_text_class ? this.props.message_text_class : ''} grey-text p-slim`}>
                  {/* {this.props.message_text} */}
                  {this.isHTML(this.props.message_text) ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(this.props.message_text, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                      }}
                    />
                  ) : (
                    this.props.message_text
                  )}
                </div>
              ) : this.state.after_confirm_active ? (
                <div className={this.state.ip_review ? 'ip-review-loader' : ''}>
                  <CircleLoader text={<p className="center">{this.state.after_confirm_text}</p>} />
                </div>
              ) : null}
              {this.state.validate_referral_code ? (
                <ValidateReferralCode
                  onInputChange_ReferralCode={this.props.onInputChange_ReferralCode}
                  editPatientReferralCode={this.props.editPatientReferralCode}
                  onReferralCodeFocus={this.props.onReferralCodeFocus}
                  onInputBlur_ReferralCode={this.onInputBlur_ReferralCode}
                />
              ) : null}
              {this.state.program_agreement ? (
                <ProgramAgreement
                  onAccept={this.props.onAccept}
                  agreeTerm1={this.props.agreeTerm1}
                  agreeTerm2={this.props.agreeTerm2}
                  onAgreeTerm1={this.props.onAgreeTerm1}
                  onAgreeTerm2={this.props.onAgreeTerm2}
                  referall_email={this.props.referall_email}
                  handleEmailChanges={this.onReferralEmailChange}
                  onOptingOut={this.props.onOptingOut}
                  valid_email={this.props.valid_email}
                />
              ) : null}
              {this.state.multiple_checkboxex ? (
                <MultipleCheckBoxes
                  disabledEdit={this.props.disabledEdit}
                  selections={this.props.selections}
                  onSelectionChange={(checkboxId) => {
                    this.onSelectInputChange(checkboxId);
                  }}
                />
              ) : null}
              {this.state.ipr_chart ? (
                <IprTeethChart
                  disabledEdit={this.props.disabledEdit}
                  ipr={this.props.ipr}
                  ipr_checked={this.props.ipr_checked}
                  ipr_note={this.state.ipr_note}
                  ipr_radioBtn_options={this.props.ipr_radioBtn_options}
                  onTextChange={(e) => {
                    this.onTextChange(e);
                  }}
                  onSelectionChange={(id) => {
                    this.onSelectInputChange(id);
                  }}
                />
              ) : null}
              {this.state.teeth_chart_with_checkboxes ? (
                <TeethChartWithCheckboxes
                  disabledEdit={this.props.disabledEdit}
                  selections={this.props.selections}
                  missing_teeth={this.props.missing_teeth}
                  onSelectionChange={(id) => {
                    this.onSelectInputChange(id);
                  }}
                />
              ) : null}
              {this.props.radio_btn_options && this.props.radio_btn_options[0] && !this.props.in_progress ? <div>{this.getRadioBtns()}</div> : null}
              {this.state.referral_code ? <ReferralCode referral_code_data={this.props.referral_code_data}></ReferralCode> : null}
              {this.state.qc_revision && !this.props.in_progress && (
                <div className="qc-reject grey-text">
                  <label>Stage for Revision:</label>
                  <div className="qc-reject__selection">
                    {Object.keys(this.props.revision_stages).map((stage, index) => {
                      return (
                        <div key={index}>
                          <input
                            type="radio"
                            value={stage}
                            checked={this.props.selected_revision_stage === stage}
                            onChange={this.props.onRevisionStageChange}
                          />
                          <span className="qc-stage">
                            {stage} -{' '}
                            {this.isHTML(this.props.revision_stages[stage]) ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: this.props.revision_stages[stage],
                                }}
                              />
                            ) : (
                              this.props.revision_stages[stage]
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {this.state.select_address && this.props.shipping_addresses && this.props.selected_address_id && this.props.preset === 'update-address' ? (
                <SelectShippingAddress
                  shipping_addresses={this.props.shipping_addresses}
                  selected_address_id={this.props.selected_address_id}
                  onSelectedAddress={this.props.onSelectedAddressChange}
                ></SelectShippingAddress>
              ) : null}
              {this.state.datearea ? (
                <div className={this.state.message ? 'grey-text datearea' : 'grey-text'}>
                  <span className={this.state.datearea_warning_active ? 'warning-text title input_description' : 'title input_description'}>
                    {this.state.datearea_text}
                  </span>
                  <div className="inline-input">
                    <TextBox
                      id={this.props.date_input_id}
                      type="date"
                      placeholder="mm/dd/yyyy"
                      className={this.state.datearea_warning_active ? 'warning-border' : ''}
                      onChange={this.onDateAreaChange}
                      onFocus={this.props.onFocus}
                      value={this.state.datearea_value ? this.state.datearea_value : ''}
                      min={getMinDate()}
                      disabled={this.props.disabledEdit}
                    />
                  </div>
                </div>
              ) : null}
              {this.state.file_upload_area ? (
                <div className="uploader_div">
                  <p className="title input_description file_upload_text">{this.props.file_upload_text}</p>
                  <Uploader
                    id={this.props.uploader_id}
                    photoUpload={this.props.filesUploaded ? this.props.filesUploaded : []}
                    upload_content={this.props.files_uploading ? this.props.files_uploading : []}
                    onUpload={this.props.onUpload}
                    onRemove={this.props.onRemove}
                    folder={this.props.folder}
                    type={this.props.type}
                    location={this.props.location ? this.props.location : 'incomplete'}
                    remove_btn={this.props.remove_btn}
                    isUploading={this.setUploadInProgress}
                    ws_id={this.props.ws_id}
                    multiple={this.props.multiple}
                    show_warning={this.showPopOutWarning}
                    disabledEdit={this.props.disabledEdit}
                    wsg_rev={this.props.wsg_rev}
                  />
                </div>
              ) : null}
              {this.state.address
                ? this.props.address_list.map((address, idx) =>
                    address.is_active ? (
                      <div className="left-align-radio" key={idx}>
                        <input
                          className="address-format-radio"
                          type="radio"
                          key={idx}
                          name="address"
                          checked={address.id === parseInt(this.props.address_selected)}
                          onChange={this.props.onAddressInputChange}
                          defaultValue={address.id}
                          data-id={address.id}
                        />

                        <span className="pointer bold-text" onClick={this.props.onAddressInputChange} data-id={address.id}>
                          {getShippingAddressName(address)} <br />
                        </span>
                        <span className="pointer2" onClick={this.props.onAddressInputChange} data-id={address.id}>
                          {getShippingAddress(address)}
                        </span>
                      </div>
                    ) : null
                  )
                : null}
              {this.state.dropdown && !this.props.in_progress ? (
                <div className="select-container margin-top-15">
                  {this.props.extra_label_dropdown ? (
                    <label className="modal-textarea-label case_cancel_text_margin_bottom">{this.props.extra_label_dropdown}</label>
                  ) : null}

                  <label
                    className={
                      'modal-textarea-label ' +
                      (this.props.header_text === 'Prospect Lost' || this.props.header_text === 'Hold Prospect' ? 'emphasis pull-left ' : null) +
                      (this.props.header_text.indexOf('Hold') !== -1 &&
                      !(this.props.header_text === 'Prospect Lost' || this.props.header_text === 'Hold Prospect')
                        ? 'margin-left-40'
                        : 'margin-right-9')
                    }
                  >
                    {this.props.label_dropdown}
                  </label>
                  <select
                    className={
                      (this.state.textarea_warning_active || this.state.dropdown_doctor_warning_active ? 'dropdown-error ' : '') +
                      (this.props.header_text === 'Prospect Lost' || this.props.header_text === 'Hold Prospect' ? 'pull-left ' : null)
                    }
                    defaultValue={'default'}
                    onChange={this.onDropdownChange}
                  >
                    <option value="default" disabled hidden>
                      {this.props.default_text}
                    </option>

                    {
                      this.props.dropdown_items
                      ? this.props.dropdown_items.map((item, idx) => {
                          return (
                            <option value={item.value} key={idx} className="dropdown-option">
                              {item.text}
                            </option>
                          );
                        })
                      : null
                    }

                    {this.props.hold_cancel_reasons
                      ? this.props.hold_cancel_reasons.map((reason, idx) => {
                          return (
                            <option value={reason.reason} key={idx} className="dropdown-option">
                              {reason.reason}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              ) : null}
              {this.state.dropdown &&
              this.state.selected_reason === 'Other' &&
              this.props.hold_cancel_reasons &&
              this.props.hold_cancel_reasons.length > 0 &&
              this.props.hold_cancel_reasons[0] &&
              !this.props.in_progress &&
              (this.props.hold_cancel_reasons[0].category === 'Hold' || this.props.hold_cancel_reasons[0].category === 'Cancel') ? (
                <div className="select-container">
                  <label className="margin-left-49">{this.props.doctornote_label ? this.props.doctornote_label : 'Note to Doctor:'}</label>
                  <textarea
                    className={
                      'form-control modal-textarea-hold-cancel ' +
                      (this.state.textarea_doctor_note_maxlength_active || this.state.textarea_doctor_note_warning_active
                        ? 'modal-textarea-error-hold-cancel'
                        : '')
                    }
                    maxLength={1000}
                    onChange={this.onTextAreaDoctorNoteChange}
                    placeholder={this.props.doctornote_placeholder}
                    rows="6"
                    value={this.state.textarea_doctor_note_value}
                    onBlur={this.onTextAreaBlur}
                    onKeyDown={(e) => this.onTextAreaKeyDown(e, 'doctor_note')}
                  />
                </div>
              ) : null}
              {(this.state.dropdown &&
                this.state.selected_reason === 'Other' &&
                this.props.hold_cancel_reasons &&
                this.props.hold_cancel_reasons.length > 0 &&
                this.props.hold_cancel_reasons[0] &&
                (this.props.hold_cancel_reasons[0].category === 'Prospect Lost' || this.props.hold_cancel_reasons[0].category === 'Prospect Hold')) ||
              (this.state.textarea &&
                this.state.header_text !== 'Prospect Lost' &&
                this.state.header_text !== 'Hold Prospect' &&
                !(this.state.qc_revision && this.props.in_progress) &&
                !this.props.hide_internal_note &&
                !this.props.in_progress) ? (
                <div className={this.state.dropdown ? 'select-container' : ''}>
                  {this.state.dropdown ? (
                    <label className="margin-left-57">
                      {this.state.header_text === 'Prospect Lost' || this.state.header_text === 'Hold Prospect' ? '' : 'Internal Note:'}
                    </label>
                  ) : null}

                  <textarea
                    className={
                      (this.state.header_text === 'Prospect Lost' || this.state.header_text === 'Hold Prospect' ? 'pull-left ' : '') +
                      (this.state.textarea_maxlength_active && !this.state.dropdown
                        ? 'form-control modal-textarea-error modal-textarea-spacing'
                        : this.state.textarea_maxlength_active && this.state.dropdown
                        ? 'form-control modal-textarea-error-hold-cancel modal-textarea-spacing'
                        : 'form-control modal-textarea' || this.state.message || this.state.datearea
                        ? 'form-control modal-textarea modal-textarea-spacing'
                        : 'form-control modal-textarea') +
                      (this.state.dropdown ? ' modal-textarea-hold-cancel ' : ' ')
                    }
                    maxLength={
                      this.state.header_text === 'Prospect Lost'
                        ? 200
                        : this.state.dropdown && this.props.header_text.indexOf('Hold') !== -1
                        ? 988
                        : this.state.dropdown && this.props.header_text.indexOf('Cancel') !== -1
                        ? 983
                        : 2500
                    }
                    onChange={this.onTextAreaChange}
                    placeholder={this.props.textarea_placeholder}
                    rows={this.state.dropdown ? '6' : '4'}
                    value={this.state.textarea_value}
                    onBlur={this.onTextAreaBlur}
                    onKeyDown={(e) => this.onTextAreaKeyDown(e, 'text_area')}
                  />
                </div>
              ) : null}
              {this.state.dropdown && this.props.post_smile_design_case_cancel && !this.props.in_progress ? (
                <div className="select-container">
                  <div className="case_cancel_text_margin">
                    {this.props.cancel_case_checkbox ? (
                      <CheckBox
                        id="cancel_case_charge_accept"
                        className="label-checkbox-ir pointer text-left"
                        isChecked={this.props.cancel_charge_accpeted}
                        label={this.props.charge_fee_label}
                        toggleChange={this.props.toggleChange}
                        label_className="modal-textarea-label text-left"
                        disabled={this.props.disabledEdit}
                      />
                    ) : null}
                  </div>
                  <label className={this.props.theme === 'bpp' ? 'modal-message-text' : 'modal-textarea-label'}>
                    <span style={{ fontWeight: 700 }}>Note: </span>
                    {this.props.cancel_case_bottom_warning_text}
                  </label>
                </div>
              ) : null}
              {this.state.teeth_chart ? <TeethChart enable_8={this.state.enable_8} /> : null}
              {this.state.awb ? <MissingTeethMeasurements /> : null}
              {this.props.children}
              {this.props.bottom_text ? <span className="bottom-text">{this.props.bottom_text}</span> : null}
              {this.state.log ? (
                <div className={this.state.loading ? 'comments-area' : 'comments-area comments-transition'}>
                  {this.state.loading ? <Loader windowSize="small" /> : <Logs log_value={this.state.log_value} log_type={this.state.log_type} />}
                </div>
              ) : null}
              {this.state.status ? <Status log_value={this.state.log_value} /> : null}
              {this.state.notes ? <Notes case_id={this.props.case_id} ireq_id={this.props.ireq_id} setNotesIndicator={this.props.setNotesIndicator} /> : null}
              {this.props.progress_notes ? (
                <ProgressNotes
                  case_id={this.props.case_id}
                  user_id={this.props.user_id}
                  progress_id={this.props.progress_id}
                  progress_notes={this.props.progress_notes}
                  setNotesIndicator={this.props.setNotesIndicator}
                />
              ) : null}
              {this.state.edit_patient ? (
                <EditPatient
                  smile_simulation={this.props.smile_simulation}
                  attribution_questions={this.props.attribution_questions}
                  patient_firstname={this.props.patient_firstname}
                  patient_lastname={this.props.patient_lastname}
                  patient_dob={this.props.patient_dob}
                  patient_dob_error={this.props.patient_dob_error}
                  patient_sex={this.props.patient_sex}
                  patient_referral_code={this.props.patient_referral_code}
                  onPatientFirstNameChange={this.props.onPatientFirstNameChange}
                  onPatientLastNameChange={this.props.onPatientLastNameChange}
                  onPatientDobChange={this.props.onPatientDobChange}
                  onPatientSexChange={this.props.onPatientSexChange}
                  patient_ref={this.props.patient_ref}
                  onPatientRefChange={this.props.onPatientRefChange}
                  theme={this.props.theme}
                  case_id={this.props.case_id}
                  shipping_address_name={this.props.shipping_address_name}
                  doctor_name={this.props.doctor_name}
                  user_roles_and_permissions={this.props.user_roles_and_permissions}
                  dso_doctors={this.props.dso_doctors}
                  dso_addresses={this.props.dso_addresses}
                  onDsoDoctorDropdownChange={this.props.onDsoDoctorDropdownChange}
                  onDsoAddressDropdownChange={this.props.onDsoAddressDropdownChange}
                  dsoDoctorDropdownId={this.props.dsoDoctorDropdownId}
                  dsoAddressDropdownId={this.props.dsoAddressDropdownId}
                  dso_doctor_info={this.props.dso_doctor_info}
                  account_role={this.props.account_role}
                  account_id={this.props.account_id}
                  inactive_case={this.props.inactive_case}
                  attributions={this.props.attributions}
                  onAttributionsChange={this.props.onAttributionsChange}
                  onAttributionOtherChange={this.props.onAttributionOtherChange}
                  is_attribution_allowed={this.props.is_attribution_allowed}
                  edit_patient_error={this.props.edit_patient_error}
                  smile_permission={this.props.smile_permission}
                  smile_simulation_doctor_id={this.props.smile_simulation_doctor_id}
                  smile_simulation_status={this.props.smile_simulation_status}
                  case_status={this.props.case_status}
                  in_progress={this.props.in_progress}
                  smile_simulation_edit_patient_char={this.props.smile_simulation_edit_patient_char}
                />
              ) : null}
              {this.state.edit_tx_plan && (
                <EditTXDEPlan
                  case_id={this.props.case_id}
                  treatment_plan={this.props.treatment_plan}
                  save_tx_plan_changes={this.props.save_tx_plan_changes}
                  changeSaveValue={this.props.changeSaveValue}
                  reloadInformation={this.props.reloadInformation}
                  photoUpload={this.props.files}
                  closeModal={this.props.onCloseButtonClick}
                  gen_2={this.props.gen_2}
                />
              )}
              {this.state.expedite_details ? (
                <ExpediteDetails
                  expedite_priority={this.props.expedite_priority}
                  shipping_address={this.props.shipping_address}
                  expedite_target_ship_date={this.props.expedite_target_ship_date}
                  onTextAreaChange={this.onTextAreaChange}
                  show_expedite_request={this.props.show_expedite_request}
                  show_expedite_approval={this.props.show_expedite_approval}
                  show_expedite_update={this.props.show_expedite_update}
                  onExpediteDropdownChange={this.props.onExpediteDropdownChange}
                  onExpediteApprovalDropdownChange={this.props.onExpediteApprovalDropdownChange}
                  onCancelExpeditedClick={this.props.onCancelExpeditedClick}
                  expedite_note={this.props.expedite_note}
                  expedite_additional_note={this.props.expedite_additional_note}
                  expedite_ship_ind={this.props.expedite_ship_ind}
                  onExpediteTargetShipDateChange={this.onExpediteTargetShipDateChange}
                  expedite_process={this.props.expedite_process}
                  user_roles_and_permissions={this.props.user_roles_and_permissions}
                  type={this.props.expedite_type}
                  cancel_permission={this.props.cancel_permission}
                  onUpdateAddressClick={this.props.onUpdateAddressClick}
                  shipping_addresses={this.props.shipping_addresses ? this.props.shipping_addresses : []}
                  selected_address_id={this.props.selected_address_id}
                  onSelectedAddress={this.props.onSelectedAddressChange}
                  confirm_btn_permission={this.props.update_btn_permission}
                  shipping_approved_ind={this.props.shipping_approved_ind}
                  target_dates={this.props.target_dates}
                  case_status={this.props.case_status}
                  id={this.props.id}
                  reloadInformation={this.props.reloadInformation}
                  input_error={this.state.textarea_warning_active}
                  date_error={this.state.expedite_details_date_error}
                  setApplianceDesignTargetDateInput={this.setApplianceDesignTargetDateInput}
                />
              ) : null}
              {this.state.account_activation && !this.props.in_progress && !this.props.account_activation_error ? (
                <AccountActivation
                  shipping_addresses={this.props.shipping_addresses}
                  selected_address_ids={this.props.selected_address_ids}
                  onAccountActivationAddressChange={this.props.onAccountActivationAddressChange}
                />
              ) : null}
              {this.state.active_cases ? (
                <ActiveCases active_cases={this.props.active_cases} is_edit_address={this.props.is_edit_address} is_dso={this.props.is_dso} />
              ) : null}
              {this.state.disable_account ? <DisableAccount dso_doctors={this.props.dso_doctors} /> : null}
              {this.state.invalid_address ? <InvalidAddress /> : null}
              {this.state.mark_shipped && !this.props.in_progress ? <MarkShipped /> : null}
              {this.state.account_creation_type ? (
                <AccountCreationType
                  onCreateDoctorClick={this.props.onCreateDoctorClick}
                  onCreateBppClick={this.props.onCreateBppClick}
                  onCreateDsoClick={this.props.onCreateDsoClick}
                  onCloseButtonClick={this.props.onCloseButtonClick}
                  user_id={this.props.user_id}
                />
              ) : null}
              {this.state.delete_profile_picture ? <DeleteProfilePicture is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page} /> : null}
              {this.state.reposition_profile_picture ? <RepositionProfilePicture handleImageLoadComplete={this.handleImageLoadComplete} /> : null}
              {this.state.draft_delete_profile_picture ? (
                <DraftDeleteProfilePicture is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page} />
              ) : null}
              {this.state.draft_reposition_profile_picture ? <DraftRepositionProfilePicture handleImageLoadComplete={this.handleImageLoadComplete} /> : null}
              {this.state.upload ? (
                this.state.upload_pdf && this.props.upload_content && this.props.upload_content.length > 0 ? (
                  this.getPDFViewer()
                ) : this.props.ireq_id ? (
                  <Uploader
                    id={this.props.ireq_id}
                    photoUpload={this.props.files_uploaded}
                    multiple={true}
                    onUpload={this.props.onUpload}
                    onRemove={this.props.onRemoveButtonClick}
                    folder={this.props.folder}
                    type={this.props.type}
                    location={this.props.location ? this.props.location : 'ireq_label'}
                    remove_btn={this.props.remove_btn}
                    isUploading={this.setUploadInProgress}
                    show_warning={this.showPopOutWarning}
                    ws_id={this.props.ws_id}
                    wire_name={this.props.wire_name}
                    wsg_rev={this.props.wsg_rev}
                    replace={this.props.replace}
                    ir_wo_history={this.props.ir_wo_history}
                  />
                ) : (
                  <Uploader
                    id={this.props.case_id}
                    photoUpload={this.props.upload_content}
                    onUpload={this.props.onUpload}
                    onRemove={this.props.onRemoveButtonClick}
                    folder={this.props.folder}
                    type={this.props.type}
                    location={this.props.location ? this.props.location : 'complete'}
                    remove_btn={this.props.remove_btn}
                    isUploading={this.setUploadInProgress}
                    prev_ws_manual_file_name={this.props.prev_ws_manual_file_name}
                    wire_name={this.props.wire_name}
                    replace={this.props.replace}
                    wsg_rev={this.props.wsg_rev}
                    wsg_manual_id={this.props.wsg_manual_id}
                    ws_version={this.props.ws_version}
                    show_warning={this.props.show_warning}
                  />
                )
              ) : null}
              {this.state.credit_hold && this.props.cases.length > 0 && !this.props.in_progress ? (
                <div className="credit-hold">
                  <div className="form-group">
                    <p className="center-text">
                      {this.props.is_credithold_on
                        ? 'The credit hold on following cases will automatically removed.'
                        : 'The following cases will automatically be placed on hold.'}
                    </p>
                    <div className="credit-hold-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Case ID</th>
                            <th>Patient</th>
                            {this.props.dso_account ? <th>Assigned To</th> : null}
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.cases.map((cases, index) => {
                            return (
                              <tr className={''} key={index}>
                                <td>{removeCaseIdInitialNumber(cases.case_id)}</td>
                                <td>
                                  {cases.first_name} {cases.last_name}
                                </td>
                                {this.props.dso_account ? <td>{cases.dso_doctor_name}</td> : null}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.selections_show && !this.props.in_progress ? (
                <div className={this.state.dropdown ? 'select-container' : ''}>
                  <div className="extra-padding-headline-bpp" />
                  {this.props.selections && this.props.selections.length > 0
                    ? this.props.selections.map((selection, index) => {
                        return (
                          <div key={index} className={'col-lg-12 center-text'}>
                            <p className="text-justify">
                              <CheckBox
                                id={selection.parts_id}
                                className={selection.hide ? 'filter-hidden text-left' : 'label-checkbox-ir pointer text-left'}
                                isChecked={selection.state}
                                label={selection.description}
                                toggleChange={() => this.props.onSelectionChangeRework(selection.parts_id)}
                                label_className={selection.hide ? 'filter-hidden text-left' : 'label-text-ir text-left'}
                              />
                            </p>
                          </div>
                        );
                      })
                    : null}
                  {this.props.checkbox_selections && this.props.checkbox_selections.length > 0
                    ? this.props.checkbox_selections.map((selection, index) => {
                        return (
                          <div key={index} className={'col-lg-12 center-text'}>
                            <p className="text-justify">
                              <CheckBox
                                id={selection.parts_id}
                                className={selection.hide ? 'filter-hidden text-left' : 'label-checkbox-ir pointer text-left'}
                                isChecked={selection.state}
                                label={selection.description}
                                toggleChange={this.props.onCheckedChange}
                                label_className={selection.hide ? 'filter-hidden text-left' : 'label-text-ir text-left'}
                              />
                            </p>
                          </div>
                        );
                      })
                    : null}
                </div>
              ) : null}
              {this.state.checklist ? <Checklist {...this.props} content={this.props.checklistContent} /> : null}
              {this.state.case_filter_preferences ? <CaseFilterPreferences /> : null}
              {this.state.pdf_viewer ? (
                <React.Fragment>
                  {this.getPDFViewer()}
                  {closePDFModalOnRedirect()}
                </React.Fragment>
              ) : null}

              {this.state.video_viewer ? (
                this.state.loading ? (
                  <CircleLoader fullscreen={true} />
                ) : (
                  <ReactPlayer url={this.state.video_loaded_url} controls playing width="100%" />
                )
              ) : null}
              {this.state.clinical_review ? (
                <div className="clinical-review">
                  <RichText
                    edit_in_progress={this.props.note_in_progress}
                    handleChange={this.props.onNoteChange}
                    value={this.props.note}
                    readOnly={false}
                    placeholder={this.props.note_placeholder}
                    onFocus={this.props.onFocusNote}
                    max_length={this.props.max_length}
                    className={this.props.character_count >= 2500 ? 'text-editor__error' : null}
                  ></RichText>
                  <div className={'p-slim grey-text character-count' + (this.props.character_count >= 2500 ? ' character-count__error' : '')}>
                    {this.props.character_count}/2500
                  </div>
                </div>
              ) : null}
              {this.state.datearea_warning_active || (this.state.mark_shipped && this.props.ship_date_error) ? (
                <div className={`modal-error${this.props.ship_date_error ? '' : '-left'}`}>
                  <p>{this.props.datearea_warning_text}</p>
                </div>
              ) : null}
              {this.props.modal_body && !this.props.in_progress ? this.props.modal_body : null}
            </div>

            {this.state.textarea_warning_active || this.state.expedite_details_date_error || this.state.expedite_details_no_update_error ? (
              <div className="modal-error">
                {this.state.expedite_details ? (
                  this.state.expedite_details_no_update_error ? (
                    <p>{this.props.no_updates_error_text}</p>
                  ) : this.state.expedite_details_date_error ? (
                    <p>{this.props.date_error_text}</p>
                  ) : (
                    <p>{this.props.textarea_warning_text}</p>
                  )
                ) : (
                  <p>{this.state.textarea_warning_text}</p>
                )}
              </div>
            ) : null}
            {this.state.textarea_doctor_note_warning_active ? (
              <div className="modal-error">
                <p>{this.props.dropdown_error ? this.props.dropdown_error : 'Please enter reason for holding case'}</p>
              </div>
            ) : null}
            {this.state.textarea_maxlength_active ? (
              <div className="modal-error">
                <p>{this.state.textarea_maxlength_message}</p>
              </div>
            ) : null}
            {this.state.textarea_doctor_note_maxlength_active ? (
              <div className="modal-error">
                <p>{this.state.textarea_maxlength_message}</p>
              </div>
            ) : null}
            {this.state.edit_patient_warning_active ? (
              <div className="modal-error">
                <p>{this.state.edit_patient_warning_text}</p>
              </div>
            ) : null}
            {this.state.ip_review &&
            this.state.after_confirm_active &&
            this.props.approveFailure &&
            !this.props.in_progress &&
            userHasPermission('BYPASS_CONVERSION_PROCESS', this.props.user_roles_and_permissions.permissions) ? (
              <div className="modal-footer">
                <button
                  type="button"
                  className={
                    this.props.theme === 'bpp'
                      ? `btn btn-light btn-negative pull-${this.state.close_btn_pos}`
                      : `btn btn--secondary pull-${this.state.close_btn_pos}`
                  }
                  // onClick={this.props.approveFailure ? this.props.onCloseButtonClick : this.props.onIncompleteClick}
                  onClick={this.props.onCloseButtonClick}
                >
                  {/* {this.props.approveFailure ? 'OK' : 'Incomplete'} */}
                  OK
                </button>
              </div>
            ) : null}
            {this.state.footer && !this.state.after_confirm_active && !this.props.in_progress && !this.props.save_tx_plan_changes ? (
              <div className="modal-footer">
                {((!this.state.approve_shipping && !this.state.mark_shipped && !this.state.erp && this.state.confirm_btn) ||
                  (this.state.expedite_details && this.props.expedite_confirm_btn) ||
                  ((this.state.profile_picture || this.state.approve_shipping || this.state.mark_shipped || this.state.erp) && this.props.confirm_btn)) &&
                !this.props.in_progress ? (
                  <button
                    id="modal_confirm_btn"
                    type="button"
                    className={
                      (this.props.theme === 'bpp'
                        ? `btn btn-light btn-spacing pull-${this.state.confirm_btn_pos}`
                        : `btn btn--${this.props.modal_class === 'modal-content-warning' ? 'revise' : 'primary'} btn-spacing pull-${
                            this.state.confirm_btn_pos
                          }`) +
                      (this.state.expedite_details &&
                      this.props.confirm_btn_permission &&
                      !userHasPermission(this.props.confirm_btn_permission, this.props.user_roles_and_permissions.permissions)
                        ? ' btn-confirm-disabled'
                        : '')
                    }
                    onClick={this.onConfirmButtonClick}
                    data-href={this.props.confirm_btn_data_href}
                    data-type={this.props.confirm_btn_data_type}
                    disabled={
                      this.props.submission_in_progress ||
                      this.props.in_progress ||
                      this.state.confirm_btn_clicked ||
                      ((this.state.shipping_details || this.state.expedite_details) &&
                        this.props.confirm_btn_permission &&
                        !userHasPermission(this.props.confirm_btn_permission, this.props.user_roles_and_permissions.permissions)) ||
                      (this.state.edit_patient &&
                        this.props.dso_doctor_info &&
                        this.props.account_id !== this.props.dso_doctor_info.id &&
                        this.props.account_role === 'DSO_Doctor') ||
                      (this.state.selections_show &&
                        this.props.checkbox_selections &&
                        this.props.checkbox_selections.length > 0 &&
                        this.props.checkbox_selections.filter((selection) => selection.state === true).length === 0) ||
                      (this.state.selections_show &&
                        this.props.selections &&
                        this.props.selections.length > 0 &&
                        this.props.selections.filter((selection) => selection.state === true).length === 0) ||
                      this.props.confirm_btn_disabled ||
                      this.state.upload_in_progress ||
                      this.props.no_edit_permission ||
                      (this.state.invalid_address && this.props.confirmButtonDisabled) ||
                      (this.state.validate_referral_code && this.props.confirmButtonDisabled) ||
                      ((this.state.mark_shipped || this.state.clinical_review) && this.props.confirm_btn_disabled) ||
                      (this.state.erp && this.props.confirm_btn_disabled) ||
                      this.props.patient_dob_error ||
                      ((this.state.draft_reposition_profile_picture || this.state.reposition_profile_picture) && !this.state.image_load_complete)
                    }
                  >
                    {this.props.no_edit_permission ? <i className="fa fa-lock lock_icon"></i> : ''}
                    {this.state.expedite_details || this.state.profile_picture || this.state.mark_shipped
                      ? this.props.confirm_btn_text
                      : this.state.confirm_btn_text}
                  </button>
                ) : null}

                {this.props.remove_btn && this.props.upload_content && this.props.upload_content.length > 0 ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-link-round pull-left"
                    data-id={this.props.case_id}
                    data-href={this.props.confirm_btn_data_href || this.props.upload_content[0].upload_data}
                    data-type={this.props.folder}
                    onClick={this.props.onRemoveButtonClick}
                  >
                    {this.state.remove_btn_text}
                  </button>
                ) : null}
                {(this.state.mark_shipped ? this.props.close_btn : this.state.close_btn) && !this.props.in_progress ? (
                  <button
                    id="modal_close_btn"
                    type="button"
                    data-dismiss="modal"
                    className={
                      (this.props.theme === 'bpp'
                        ? `btn btn-light pull-${this.state.close_btn_pos}`
                        : `btn btn--${
                            this.props.modal_class === 'modal-content-warning'
                              ? 'danger-outline'
                              : this.props.close_btn_color
                              ? this.props.close_btn_color
                              : 'secondary'
                          } pull-${this.state.close_btn_pos}`) +
                      (this.props.expedite_process &&
                      this.props.expedite_process.status_code === `${this.props.expedite_type} Expedite Request Submitted` &&
                      !this.props.show_expedite_approval &&
                      isAfterSmileDesignApproval(this.props.case_status) &&
                      (userHasPermission(
                        `${this.props.expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_APPROVE`,
                        this.props.user_roles_and_permissions.permissions
                      ) ||
                        userHasPermission(
                          `${this.props.expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REJECT`,
                          this.props.user_roles_and_permissions.permissions
                        ))
                        ? ''
                        : ' btn-negative')
                    }
                    onDoubleClick={this.onDoubleClick}
                    onClick={
                      (this.state.expedite_details && this.props.close_btn_text === 'Reject') || this.props.close_btn_text === 'Do Not Accept'
                        ? this.props.onRejectButtonClick
                        : this.props.onCloseButtonClick
                    }
                    disabled={
                      this.props.submission_in_progress ||
                      this.state.upload_in_progress ||
                      ((this.state.shipping_details || this.state.expedite_details) &&
                        !this.props.show_expedite_approval &&
                        this.props.close_btn_permission &&
                        !userHasPermission(this.props.close_btn_permission, this.props.user_roles_and_permissions.permissions) &&
                        (userHasPermission(
                          `${this.props.expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_APPROVE`,
                          this.props.user_roles_and_permissions.permissions
                        ) ||
                          userHasPermission(
                            `${this.props.expedite_type === 'Case' ? 'CASE' : 'IREQ'}_EXPEDITE_REJECT`,
                            this.props.user_roles_and_permissions.permissions
                          ))) ||
                      ((this.state.upload_pdf || this.state.upload) && this.state.upload_in_progress)
                    }
                  >
                    {this.state.expedite_details ||
                    (this.props.close_btn_text && this.props.close_btn_text !== 'Close') ||
                    this.state.approve_shipping ||
                    this.state.mark_shipped ||
                    this.state.erp
                      ? this.props.close_btn_text
                      : this.state.close_btn_text}
                  </button>
                ) : null}
              </div>
            ) : null}
            {this.state.submitting ? (
              <React.Fragment>
                {this.state.loading ? <CircleLoader fullscreen={false} /> : null}
                <div id="submitting" />
              </React.Fragment>
            ) : null}
          </div>
        </div>
        {this.state.popout_error_message ? (
          <ErrorMessage className="error-message-container" title={this.props.popout_error_title} onClose={this.closePopOutWarning}>
            <div className="warning-display" id="warning-submit">
              <ul className="wizard-error-text"></ul>
            </div>
          </ErrorMessage>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    smile_design_target_date_input: getSmileDesignTargetDateInput(state),
    appliance_design_target_date_input: getApplianceDesignTargetDateInput(state),
    preferred_shipping_input: getPreferredShippingInput(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setApplianceDesignTargetDateInput: setApplianceDesignTargetDateInput,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
