import Axios from 'axios';
import { setTokenHeader } from '../common/functions';
import { getDoctorsIdFromRoute } from '../common/route';
import { handleHttpRequestError } from '../common/error';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

const UserPermissionsContext = React.createContext();

class UserPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      permissions_categories: [],
      permissions_loaded: false,
      program_enrollment: [],
      doctor_program_enrollment: [],
      terms: false,
    };
  }

  componentDidMount() {
    this.fetchUserPermissions();
  }

  /**
   * Retrieves user's permissions and roles
   *
   * @function
   */
  fetchUserPermissions = async () => {
    setTokenHeader();
    const doctorId = getDoctorsIdFromRoute();

    let terms = false;
    try {
      await Axios.get(`/apiV2/agreement`);
    } catch (e) {
      terms = true;
    }
    try {
      const res = await Axios.get(`/apiV2/permission`);
      const permissions = res?.data?.permission_list_raw || [];
      const permissionsCategories = res?.data?.permission_categories_raw || [];
      if (permissions) {
        if (res?.data?.maintenance_mode_restrictive && !permissions.includes('MAINTENANCE_MODE')) {
          window.location.href = 'https://maintenance.inbrace.com/';
        }

        this.setState({
          permissions,
          permissions_categories: permissionsCategories,
          program_enrollment: res.data.program_enrollment,
          user_roles: res.data.user_roles,
          user_id: res.data.user_id,
          doctor_id: res.data.doctor_id,
          doctor_role: res.data.doctor_role,
          terms,
        });
      }
      if (doctorId) {
        const doctorRes = await Axios.get(`/apiV2/account_light/${doctorId}`);
        const doctor_program_enrollment = doctorRes?.data?.program_enrollment || [];
        this.setState({ doctor_program_enrollment });
      }
    } catch (err) {
      handleHttpRequestError(err, this);
    } finally {
      this.setState({ permissions_loaded: true });
    }
  };

  render() {
    return (
      this.state.permissions_loaded && (
        <UserPermissionsContext.Provider value={{ ...this.state, fetchUserPermissions: this.fetchUserPermissions }}>
          {this.props.children}
        </UserPermissionsContext.Provider>
      )
    );
  }
}

UserPermission = withRouter(UserPermission);

const withUserPermission = (WrappedComponent) => {
  const OuterComponent = (props) => {
    return (
      <UserPermission>
        <UserPermissionsContext.Consumer>{({ permissions }) => <WrappedComponent {...props} permissions={permissions} />}</UserPermissionsContext.Consumer>
      </UserPermission>
    );
  };

  return OuterComponent;
};

export { UserPermission, UserPermissionsContext, withUserPermission };
