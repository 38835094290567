import { CaseStatus } from '../../../common/case/case_details.constants';
import { SETUP_PREFIX } from '../../setup_viewer/components/common/functions';
import { getBusinessRoleList } from '../../../common/helpers';
import { isPostApprovalStage } from '../../../common/case/case_status';

export function getDoctorName(nextSetup) {
  const inbrace_name = 'InBrace';
  const firstLog = (nextSetup.log || []).at(0) || {};
  const hasValidLogName = firstLog.text_created_by_first_name && firstLog.text_created_by_last_name;

  if (hasValidLogName) {
    const first_name = nextSetup.log[0].text_created_by_first_name;
    const last_name = nextSetup.log[0].text_created_by_last_name;
    const role = nextSetup.log[0].role;

    const doctor_name = `Dr. ${last_name}`;
    const dso_name = `${first_name} ${last_name}`;

    if (role === 'Business') return inbrace_name;
    if (role === 'DSO') return dso_name;
    return doctor_name;
  }

  return inbrace_name;
}

export function getInbraceComment(setupLog) {
  const latestLog = setupLog.at(-1);
  if (!latestLog.text) {
    return {};
  }
  return {
    comment: latestLog.text,
    comment_date: latestLog.date,
    name: 'InBrace',
  };
}

export function getDoctorComment(setupStatus, nextSetup) {
  const hasValidLogText = nextSetup?.log?.[0]?.text;
  const isInternallyRejected = setupStatus === 'Internally Rejected';

  if (hasValidLogText && !isInternallyRejected) {
    return {
      comment: nextSetup.log[0].text,
      comment_date: nextSetup.log[0].date,
      name: getDoctorName(nextSetup),
    };
  }
  return {};
}

export function shouldShowSetup(setup, caseDetailsRole) {
  const hasSetupLog = setup.log && setup.log.length > 0;
  const hasAccessToConvertedSetup = getBusinessRoleList().includes(caseDetailsRole);
  const releaseStatus = [CaseStatus.ProviderEdit, CaseStatus.SetupReleaseReady];

  if (hasSetupLog) {
    const latestLogStatus = setup.log.at(-1).status_code;
    const isReadyForRelease = releaseStatus.includes(latestLogStatus);
    const isApprovalStatus = latestLogStatus === 'STATUS_DOCTOR_APPROVAL';
    const isProviderEdit = latestLogStatus === CaseStatus.ProviderEdit;
    const isInternal = setup.setup_status.includes('Internally');

    return (hasAccessToConvertedSetup && isReadyForRelease && !isInternal) || isApprovalStatus || isProviderEdit;
  }
  return false;
}

export function hasRevision(setup_process, i) {
  // set up was revised if it
  const isLatestSetup = i + 1 === setup_process.length;
  if (isLatestSetup) {
    const isProviderEditReview = setup_process[i].log?.at(-1)?.status_code === CaseStatus.ProviderEdit;
    return isProviderEditReview;
  }

  const ifsUploadApproved = [CaseStatus.IFSUpload, CaseStatus.IFSRecipe].includes(setup_process[i + 1].log?.[0]?.status_code);
  return !ifsUploadApproved;
}

export function getSetupStatus(setup_process, case_detail) {
  const { manufacturing_process } = case_detail;
  const is_case_approved = manufacturing_process?.log?.length > 0;
  const case_status_code = case_detail.status_code;
  const is_post_approval = isPostApprovalStage(case_status_code, false);
  let lastest_setup_status_code = '';
  lastest_setup_status_code = setup_process.at(-1).log.at(-1).status_code;

  if (is_case_approved || is_post_approval) return 'Approved';
  if (case_status_code === 'STATUS_HOLD') return 'On Hold';
  if (case_status_code === 'STATUS_CANCEL') return 'Case Cancelled';
  if (case_status_code === 'Doctor Provide Clarification') return 'Pending Doctor Clarification';
  if (lastest_setup_status_code === 'Setup Ready for Release') return 'Uploaded';
  if (lastest_setup_status_code !== 'STATUS_DOCTOR_APPROVAL') return 'Revision Request Submitted';

  return 'Review Smile Design';
}

export function buildHistoryListFromCaseDetail(setupProcess = [], caseDetailsRole) {
  const setups = [];
  for (let i = setupProcess.length - 1; i >= 0; i--) {
    const setup = setupProcess[i];
    const setupLog = setup.log.filter((l) => l.status_code !== CaseStatus.ProviderEdit);

    if (setupLog.length > 0 && shouldShowSetup(setup, caseDetailsRole)) {
      if (hasRevision(setupProcess, i)) {
        setups.push({
          title: `${SETUP_PREFIX}${setup.index} - Revision`,
          ...getDoctorComment(setup.setup_status, setupProcess[i + 1]),
        });
      }
      setups.push({
        title: `${SETUP_PREFIX}${setup.index}`,
        ...getInbraceComment(setupLog),
      });
    }
  }
  return setups;
}
