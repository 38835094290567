import React, { useContext } from 'react';
import WasmSidebarHistoryItem from './wasm_sidebar_history_item';
import WasmSidebarStatus from './wasm_sidebar_status';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { connect } from 'react-redux';
import { getCaseFileList } from '../../../redux/reducers/ipp/case_details/case_files';
import { UserPermissionsContext } from '../../../context/user_permission';
import { getBusinessRoleList } from '../../../common/helpers';
import { CaseStatus } from '../../../common/case/case_details.constants';

function getIfsHistory(caseFileList, doctorRole) {
  const hasAccessToIfs = getBusinessRoleList().includes(doctorRole);

  if (caseFileList?.case_id && hasAccessToIfs) {
    const { setup_process } = caseFileList[caseFileList.case_id];
    const latestSetup = setup_process[setup_process.length - 1];
    const latestLog = latestSetup.log[latestSetup.log.length - 1];
    const isIfs = latestSetup.is_inbrace_ifs_setup;
    const isReadyForRelease = [CaseStatus.IFSReadyForRelease, CaseStatus.IFSReview].includes(latestLog.status_code);

    const ifs = isIfs && isReadyForRelease;
    const setupNum = latestSetup.index;
    const providerEdit = latestSetup.provider_edit ? ' - Revision' : '';

    return {
      ifs: ifs,
      title: `Smile Design ${setupNum}${providerEdit} (IFS)`,
      name: 'InBrace',
      comment: latestSetup.comment ?? latestLog.text,
      comment_date: latestLog.date,
    };
  }
}

function displayRevisionSection(history_list, onOpenModal) {
  if (history_list.length > 0) {
    const title = history_list[0].title;
    const rev_title = `${title} - Revision`;
    return <WasmSidebarHistoryItem title={rev_title} size={history_list.length} showReview collapseDisabled onOpenModal={onOpenModal} />;
  }
  return null;
}

function WasmSidebarHistory(props) {
  const { history_list, case_file_list, status, is_revising, error, selectedTreatmentPlanError, onOpenModal } = props;
  const isLoadingWasmError = !!error || !!selectedTreatmentPlanError;
  const isReviewStatus = status === 'Review Smile Design' && !is_revising;
  const { doctor_role } = useContext(UserPermissionsContext);

  const ifsHistory = getIfsHistory(case_file_list, doctor_role);

  return (
    <div className="wasm-sidebar-main-content">
      <div className="wasm-sidebar-detail-title header header--bold">Smile Design History</div>
      <div className="wasm-sidebar-detail-list scrollbar--dark">
        {ifsHistory?.ifs && <WasmSidebarHistoryItem {...ifsHistory} size={history_list.length} onOpenModal={onOpenModal} />}
        {history_list?.length > 0 && <WasmSidebarStatus status={status} is_revising={is_revising} />}
        <div className="wasm-sidebar-history-list">
          {is_revising && !isLoadingWasmError && displayRevisionSection(history_list, onOpenModal)}
          {history_list.map((history, i) => (
            <WasmSidebarHistoryItem
              key={i}
              {...history}
              size={history_list.length}
              showReview={!isLoadingWasmError && isReviewStatus && i === 0}
              onOpenModal={onOpenModal}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    is_revising: wasm_state.is_revising,
    case_file_list: getCaseFileList(state),
    error: wasm_state.error,
    selectedTreatmentPlanError: wasm_state.selectedTreatmentPlanError,
  };
};

export default connect(mapStateToProps)(WasmSidebarHistory);
