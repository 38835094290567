import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Axios from 'axios';
import _ from 'lodash';

import ApprovalConfirmation from '../../setup_viewer/components/modal/approval_confirmation';
import Modal from '../../setup_viewer/components/modal/modal';
import ModelChangesModal from '../modal/model_changes_modal';
import RevisionConfirmation from '../modal/revision_confirmation';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { addOnCloseListener, getNewTreatmentPlanName, removeOnCloseListener, saveUploadMetadata } from '../wasm_helper';
import {
  onChangeStep,
  saveRevisionEnd,
  saveRevisionError,
  saveRevisionStart,
  saveRevisionSuccess,
  setLastActiveTreatmentPlan,
  setReviseState,
  setRevisedTreatmentPlan,
  setRevisionNote,
  updateIPRDiff,
  updateIprState,
  updateMovement,
  updateTMTDiff,
} from '../../../redux/actions/wasm_viewer/wasm_viewer';
import {
  createNewTreatmentPlan,
  deleteCurrentTreatmentPlan,
  getCaseInfoState,
  lockCurrentTreatmentPlan,
  setWasmActiveTreatmentPlan,
  wasmClearHistory,
} from '../wasm_controller/wasm_controller';
import CircleLoader from '../../loader/circle_loader';
import { sendApprovalRequest, sendRevisionRequest } from './wasm_sidebar_helper';
import {
  getCaseDetails,
  getCaseDetailsLoading,
  getCasePrimeStatus,
  getCaseStages,
  getIsRevisionDraftSaved,
  getPrimeCaseIdAndEslId,
  getRevisionLastSavedAt,
} from '../../../redux/reducers/common/common_case_details';
import { fetchCaseDetails, fetchCaseDetailsSilently } from '../../../redux/actions/common/common_case_details';
import { fetchCaseFileData } from '../../../redux/actions/ipp/case_details/case_files';
import { getCaseFileList, getCaseFileListLoading } from '../../../redux/reducers/ipp/case_details/case_files';
import UnsavedChangesModal from '../modal/unsaved_changes_modal';
import WasmSidebarRevisionNotes from './wasm_sidebar_revision_notes';
import WasmSidebarRevisionButtons from './wasm_sidebar_revision_buttons';
import WasmSidebarApprovalButtons from './wasm_sidebar_approval_buttons';
import { openFeedbackRatingFormPostSmileDesign } from '../../../redux/actions/bpp/feedback_rating_form';
import { removeEmoji } from '../../../common/functions';
import { ApiServiceInstance } from '../../../common/api_service';
import SessionExpire from '../../../common/session_expire';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../../common/storage';
import { withIprAndTmtChanges } from '../hoc/with-ipr-and-tmt.changes';
import { dateToFormatted } from '../../../common/date';
import { WASM_PROP } from '../wasm_controller/wasm_controller_const';
import { WASM_IPR_MODE } from '../wasm-constants';
import { ETmtKey } from '../movement_table/constants';
import { resetAutosave } from '../signals/autosave';
import { getButtonClearance } from './approval_button_helpers';
import { ECasePrimeStatus } from '../../../common/case/case-prime-status';

function WasmSidebarApproval(props) {
  const [isApprove, setIsApprove] = useState(false);
  const [isCancelRevise, setIsCancelRevise] = useState(false);
  const [isReviseConfirm, setIsReviseConfirm] = useState(false);
  const [isViewChanges, setIsViewChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const {
    loading: wasm_loading,
    case_details: caseDetails,
    fetchCaseDetails,
    fetchCaseDetailsSilently,
    fetchCaseFileData,
    case_details_loading,
    case_file_list_loading,
    is_revising,
    setReviseState,
    name,
    saveRevisionStart,
    saveRevisionSuccess,
    saveRevisionError,
    saveRevisionEnd,
    openFeedbackForm,
    revisionNote,
    setRevisionNote,
    iprTable,
    iprDiff,
    tmtDiff,
    revisionLastSavedAt,
    isRevisionDraftSaved,
    lastActiveTreatmentPlan,
    revisedTreatmentPlan,
    primeCaseStatus,
    caseStages,
    onFetchIPRAndTMTChanges,
    setLastActiveTreatmentPlan,
    setRevisedTreatmentPlan,
    onChangeStep,
    onOpenModal,
    undo,
    updateIprState,
    updateMovement,
  } = props;

  const { approved_disabled, approve_tooltip } = getButtonClearance(caseDetails);
  const trimmedNote = revisionNote.trim();
  const hasRevisionNote = Boolean(trimmedNote);
  const hasModelChange = !_.isEmpty(tmtDiff) || !_.isEmpty(iprDiff);
  const areChangesEditsOnly = hasModelChange && !hasRevisionNote;
  const revisionModalTheme = hasRevisionNote || hasModelChange ? 'primary' : 'danger';
  const isDraftSavedDateHidden = !undo || !is_revising;

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    if (hasRevisionNote || hasModelChange) {
      addOnCloseListener();
    } else {
      removeOnCloseListener();
    }
    return removeOnCloseListener;
  }, [hasRevisionNote, hasModelChange]);

  const postApprovalFeedback = (doctor) => {
    Axios.get('/apiV2/account_light', {
      headers: { 'Content-Type': 'application/json' },
    }).then((res) => {
      if (res?.data?.show_approval_smile_design_feedback) {
        openFeedbackForm();
      }
    });
  };

  const onClickApprove = () => {
    const { case_id } = caseDetails;
    setLoading(true);
    resetAutosave();
    sendApprovalRequest(caseDetails)
      .then(() => {
        setLoading(false);
        fetchCaseDetails(case_id, { onSuccess: (data) => postApprovalFeedback(data.doctor) });
        fetchCaseFileData(case_id);
        setIsApprove(false);
        sendRefreshCaseListMessage();
        sendRefreshDashboardMessage();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response && err.response.status === 409) {
          setIsApprove(false);
          setIsSessionExpired(true);
        }
      });
  };

  const scrollToSidebarStatus = () => {
    const sidebarStatus = document.getElementsByClassName('wasm-sidebar-status')[0];
    if (sidebarStatus) sidebarStatus.scrollIntoView({ behavior: 'smooth' });
  };

  const onClickRevise = () => {
    const caseInfo = getCaseInfoState();
    setLastActiveTreatmentPlan(caseInfo[WASM_PROP.CaseInfo.ActiveTreatmentPlanIndex]);
    setReviseState(true);
    const nextStage = caseStages.idealStage;
    onChangeStep(nextStage);
    const currentPlanName = caseInfo[WASM_PROP.CaseInfo.ActiveTreatmentPlanName];
    const nextPlanName = primeCaseStatus === ECasePrimeStatus.Sub ? getNewTreatmentPlanName(currentPlanName) : getNewTreatmentPlanName(name);
    const createPlanResponse = createNewTreatmentPlan(nextPlanName);
    // Treatment plan might have been created already, so we just need to activate it
    if (createPlanResponse.status === 404) {
      const plans = caseInfo[WASM_PROP.CaseInfo.Plans];
      const nextActivePlanIndex = plans.findIndex((p) => p.Name === nextPlanName);
      if (typeof nextActivePlanIndex === 'number') {
        setWasmActiveTreatmentPlan(nextActivePlanIndex);
      }
    }
    wasmClearHistory();
    setRevisionNote('');
    sendRefreshCaseListMessage();
    sendRefreshDashboardMessage();
    scrollToSidebarStatus();
  };

  const onClickCloseRevise = () => {
    if (hasRevisionNote || hasModelChange) {
      setIsCancelRevise(true);
      if (typeof onOpenModal === 'function') {
        onOpenModal();
      }
    } else {
      onCancelRevise();
    }
  };

  const onCancelRevise = () => {
    const { case_id: caseId } = caseDetails;
    const { caseId: primeCaseId, eslingualId } = props.primeCaseIds;
    setReviseState(false);
    // this is needed to delete recently created plan which is editable yet
    if (revisedTreatmentPlan) {
      setWasmActiveTreatmentPlan(revisedTreatmentPlan);
      setRevisedTreatmentPlan();
    }
    deleteCurrentTreatmentPlan();
    if (typeof lastActiveTreatmentPlan === 'number') {
      setWasmActiveTreatmentPlan(lastActiveTreatmentPlan);
      setLastActiveTreatmentPlan();
    }
    wasmClearHistory();
    resetAutosave();
    updateIprState(WASM_IPR_MODE.initial);
    updateMovement(ETmtKey.Occlusal);
    setTimeout(async () => {
      await Promise.all([
        saveUploadMetadata(primeCaseId, eslingualId, saveRevisionSuccess, saveRevisionError),
        ApiServiceInstance.cancelRevisionDraft(caseId, revisionNote),
      ]);
      fetchCaseDetailsSilently(caseId);
    }, 300);
  };

  const onConfirmRevision = async () => {
    const handleErrors = (err) => {
      saveRevisionError(err);
      setLoading(false);
      console.log(err);
      if (err.response && err.response.status === 409) {
        setIsReviseConfirm(false);
        setIsSessionExpired(true);
      }
    };

    try {
      saveRevisionStart();
      const { case_id: caseId } = caseDetails;
      const { caseId: primeCaseId, eslingualId } = props.primeCaseIds;
      setLoading(true);

      const sendTransitionRequest = async () => {
        try {
          await sendRevisionRequest(caseId, trimmedNote, hasModelChange);
          saveRevisionSuccess();
          setLoading(false);
          setReviseState(false);
          fetchCaseDetails(caseId);
          fetchCaseFileData(caseId);
          openFeedbackForm();
          sendRefreshCaseListMessage();
          sendRefreshDashboardMessage();
        } catch (err) {
          handleErrors(err);
        }
      };

      if (hasModelChange) {
        lockCurrentTreatmentPlan();
        await saveUploadMetadata(primeCaseId, eslingualId, sendTransitionRequest, handleErrors);
      } else {
        await sendTransitionRequest();
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  const handleNoteChange = (e) => {
    const newNote = removeEmoji(e.target.value);
    setRevisionNote(newNote);
  };

  const handleViewChanges = () => {
    setIsViewChanges(true);
    onFetchIPRAndTMTChanges();
    if (typeof onOpenModal === 'function') {
      onOpenModal();
    }
  };

  /**
   * Handles the required procedures needed to start the review confirmation process.
   *
   * @async
   * @function handleRevisionConfirmation
   * @returns {Promise<void>} A Promise that resolves once the revision confirmation is handled.
   */
  const handleRevisionConfirmation = async () => {
    const openRevisionModal = () => {
      if (typeof onOpenModal === 'function') {
        onOpenModal();
      }
    };

    const onRevisionMetadataSaveSuccess = () => {
      console.log('Metadata changes saved on AWS!');
      setIsReviseConfirm(true);
      setLoading(false);
      openRevisionModal();
    };

    const onRevisionMetadataSaveError = (err) => {
      saveRevisionError(err);
      setLoading(false);
    };

    /*
     * If the approval is disabled it means that the case is either pending financial approval or has some other
     * condition that doesn't allow it to proceed with the approval. If that's the case, then we preemptively save the
     * case to make sure that the doctor doesn't lose his changes when he finds out that the approval button is disabled.
     *
     * This is just a safe measure to prevent the doctor from losing his changes, this will not lock the current TP and
     * will trigger another approval when the criteria are met.
     *
     * if the conditions are met, then we do not trigger the save at this point and just proceed with the display
     * of the revise modal.
     */
    if (approved_disabled && areChangesEditsOnly) {
      try {
        saveRevisionStart();
        setLoading(true);
        const { caseId: primeCaseId, eslingualId } = props.primeCaseIds;
        await saveUploadMetadata(primeCaseId, eslingualId, onRevisionMetadataSaveSuccess, onRevisionMetadataSaveError);
        saveRevisionEnd();
      } catch (err) {
        console.error(err);
        onRevisionMetadataSaveError(err);
      }
    } else {
      setIsReviseConfirm(true);
      openRevisionModal();
    }
  };

  if (loading || case_details_loading || case_file_list_loading) {
    return (
      <div className="center">
        <CircleLoader />
      </div>
    );
  }

  return (
    <div className="wasm-sidebar-approval">
      <HideContentIf condition={!is_revising || wasm_loading}>
        <WasmSidebarRevisionNotes note={revisionNote} onNoteChange={handleNoteChange} onClose={onClickCloseRevise} />
      </HideContentIf>
      <HideContentIf condition={wasm_loading}>
        <div className="wasm-sidebar-approval-buttons">
          {is_revising ? (
            <>
              <WasmSidebarRevisionButtons onClickViewChanges={handleViewChanges} onClickConfirm={handleRevisionConfirmation} />
              <HideContentIf condition={isDraftSavedDateHidden || !isRevisionDraftSaved}>
                <div className="wasm-sidebar-revision-modified-date">Last Saved {dateToFormatted(revisionLastSavedAt)}</div>
              </HideContentIf>
            </>
          ) : (
            <WasmSidebarApprovalButtons
              onClickApprove={() => {
                setIsApprove(true);
                if (typeof onOpenModal === 'function') {
                  onOpenModal();
                }
              }}
              onClickRevise={onClickRevise}
              disabled={wasm_loading}
            />
          )}
        </div>
      </HideContentIf>
      <Modal show={isApprove} onCloseModal={() => setIsApprove(false)}>
        <ApprovalConfirmation confirm={onClickApprove} cancel={() => setIsApprove(false)} />
      </Modal>
      <div className={'wasm-view-changes-modal'}>
        <Modal
          show_close_button
          className={'revision-confirmation-modal'}
          theme={revisionModalTheme}
          show={isReviseConfirm}
          onCloseModal={() => setIsReviseConfirm(false)}
        >
          <HideContentIf condition={!isReviseConfirm}>
            <RevisionConfirmation
              ipr={iprTable}
              tmt={tmtDiff}
              revision_note={trimmedNote}
              has_revision_note={hasRevisionNote}
              has_model_change={hasModelChange}
              confirm={onConfirmRevision}
              disabled={approved_disabled && areChangesEditsOnly}
              approveTooltip={approve_tooltip}
              cancel={() => setIsReviseConfirm(false)}
            />
          </HideContentIf>
        </Modal>
      </div>
      <div className={'wasm-view-changes-modal'}>
        <Modal show={isViewChanges} onCloseModal={() => setIsViewChanges(false)}>
          {isViewChanges && <ModelChangesModal ipr={iprTable} tmt={tmtDiff} revision_note={revisionNote} close={() => setIsViewChanges(false)} />}
        </Modal>
      </div>
      <Modal show={isCancelRevise} onCloseModal={() => setIsCancelRevise(false)}>
        <UnsavedChangesModal confirm={onCancelRevise} cancel={() => setIsCancelRevise(false)} />
      </Modal>
      {isSessionExpired && <SessionExpire />}
    </div>
  );
}

WasmSidebarApproval.propTypes = {
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
  onFetchIPRAndTMTChanges: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const wasmViewerState = getWasmViewerState(state);
  const case_details = getCaseDetails(state);
  return {
    case_details,
    case_details_loading: getCaseDetailsLoading(state),
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
    is_revising: wasmViewerState.is_revising,
    tmtDiff: wasmViewerState.tmtDiff,
    iprDiff: wasmViewerState.iprDiff,
    iprTable: wasmViewerState.ipr_table,
    loading: wasmViewerState.loading,
    error: wasmViewerState.error,
    revisionNote: wasmViewerState.revisionNote,
    treatmentPlanStage: wasmViewerState.step,
    revisionLastSavedAt: getRevisionLastSavedAt({ case_details }),
    isRevisionDraftSaved: getIsRevisionDraftSaved({ case_details }),
    lastActiveTreatmentPlan: wasmViewerState.lastActiveTreatmentPlan,
    revisedTreatmentPlan: wasmViewerState.revisedTreatmentPlan,
    undo: wasmViewerState.undo,
    primeCaseIds: getPrimeCaseIdAndEslId(state),
    primeCaseStatus: getCasePrimeStatus(state),
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseDetails: fetchCaseDetails,
      fetchCaseDetailsSilently: fetchCaseDetailsSilently,
      fetchCaseFileData: fetchCaseFileData,
      setReviseState: setReviseState,
      openFeedbackForm: openFeedbackRatingFormPostSmileDesign,
      setRevisionNote: setRevisionNote,
      updateIPRDiff: updateIPRDiff,
      updateTMTDiff: updateTMTDiff,
      updateIprState: updateIprState,
      setLastActiveTreatmentPlan: setLastActiveTreatmentPlan,
      setRevisedTreatmentPlan: setRevisedTreatmentPlan,
      onChangeStep: onChangeStep,
      saveRevisionStart: saveRevisionStart,
      saveRevisionEnd: saveRevisionEnd,
      saveRevisionSuccess: saveRevisionSuccess,
      saveRevisionError: saveRevisionError,
      updateMovement: updateMovement,
    },
    dispatch
  );

export default withIprAndTmtChanges(connect(mapStateToProps, mapDispatchToProps)(WasmSidebarApproval));
