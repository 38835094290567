import React, { Component } from 'react';
import Axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCaseDetails, getCaseDetailsError, getCaseDetailsLoading, getCaseStages } from '../../../../../../redux/reducers/common/common_case_details';
import { fetchCaseDetails } from '../../../../../../redux/actions/common/common_case_details';
import { fetchCaseFileData } from '../../../../../../redux/actions/ipp/case_details/case_files';
import { openFeedbackRatingFormPostSmileDesign } from '../../../../../../redux/actions/bpp/feedback_rating_form';

import { HistoryListItemReviewButtons } from './history_list_item_review_buttons';
import { getCaseDetailByCaseId } from '../../../common/functions';
import { removeEmoji } from '../../../../../../common/functions';
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../../../../../common/storage';
import CircleLoader from '../../../../../loader/circle_loader';
import RetryButton from '../../../extras/retry_button';
import { onChangeStep } from '../../../../../../redux/actions/wasm_viewer/wasm_viewer';

const FINANCIAL_TOOLTIP = 'Please approve financial status first';
const CLINICAL_REVIEW_TOOLTIP = 'This case is under clinical review and cannot be approved';

/**
 * Contains the interaction of the user to the approval and rejection of a setup
 * @component
 * @alias SetupViewerHistoryListItemReview
 */
class HistoryListItemReview extends Component {
  state = {
    loading: false,
    error: null,
    revise: false,
    revise_reason: '',
  };

  /**
   * Updates the revise reason text in the state
   *
   * @function
   * @param {Object} evt - Contains the input field dom event
   */
  onChangeReviseReason = (evt) => {
    this.setState({ revise_reason: removeEmoji(evt.target.value) });
  };

  /**
   * Makes a call to the backend to update the setup to an approved state
   *
   * @function
   */
  onClickApprove = () => {
    const { case_id, doctor, cases } = this.props.case_details;
    const inbrace_ifs_required = cases[cases.length - 1]?.production_tx_guide?.inbrace_ifs_required;
    const provider_edit = cases[cases.length - 1]?.provider_edit;
    const action = provider_edit ? 'approve_with_provider_edit' : inbrace_ifs_required ? 'approve_with_inbrace_ifs' : 'approve';

    this.setState({ loading: true });

    Axios.post(`/apiV2/caseaction/${case_id}/${action}`)
      .then(() => {
        this.props.fetchCaseDetails(case_id);
        if (action !== 'approve_with_provider_edit') this.sendApprovalEmail(case_id);

        return Axios.get(`/apiV2/account_light`);
      })
      .then((res) => {
        let show_feedback = res?.data?.show_quarter_feedback;
        if (res?.data?.role === 'Business') {
          show_feedback = doctor.show_quarter_feedback;
        }
        if (this.props?.history?.length >= 3 || show_feedback) {
          this.props.openFeedbackForm();
        }
        this.setState({ loading: false, error: null });
      })
      .catch((err) => {
        this.setState({ loading: false, error: err });
      })
      .finally(() => {
        sendRefreshCaseListMessage();
        sendRefreshDashboardMessage();
      });
  };

  /**
   * Sends an email out after approval is completed
   *
   * @function
   * @param {String} case_id - Contains the Case ID
   */
  sendApprovalEmail(case_id) {
    if (case_id.includes('-DE')) {
      Axios.post(`/api/email/?slug=setup-approval-email-de-1&caseId=${case_id}&method=standard&provider=${window.location.origin}`);
    } else {
      Axios.post(`/api/email/?slug=setup-approval-email-case-1&caseId=${case_id}&method=standard&provider=${window.location.origin}`);
    }
  }

  /**
   * Makes a call to the backend to update the setup to an rejected state
   *
   * @function
   */
  onClickRevise = () => {
    const { case_id, caseStages } = this.props.case_details;

    const formData = { status_comment: this.state.revise_reason };
    this.setState({ loading: true });
    Axios.post(`/apiV2/caseaction/${case_id}/revision`, formData)
      .then((res) => {
        this.props.fetchCaseDetails(case_id);
        this.props.fetchCaseFileData(case_id);
        this.props.onChangeStep(caseStages.idealStage);
        this.setState({ loading: false, error: null });
      })
      .catch((err) => this.setState({ loading: false, error: err }))
      .finally(() => {
        sendRefreshCaseListMessage();
        sendRefreshDashboardMessage();
      });
  };

  /**
   * Checks if the dso doctor accessing is the same dso doctor created
   * @function
   * @return {Boolean} Whether they are different dso doctor
   */
  isDifferentDsoDoctor() {
    return (
      this.props.case_details.dso_doctor &&
      this.props.case_details.role === 'DSO_Doctor' &&
      this.props.case_details.dso_doctor.user_id !== this.props.case_details.request_id
    );
  }

  /**
   * Checks if it is a initial dso case with unapproved financial status
   * @function
   * @return {Boolean} Whehter true or not
   */
  isInitialDSOCaseWithUnapprovedFinancialStatus() {
    const case_detail = getCaseDetailByCaseId(this.props.case_details, this.props.case_details.case_id);
    const isInitialCase = this.props.case_details.case_id.indexOf('-') === -1;
    const isDsoCase = case_detail.is_dso_case;
    const isUnapprovedFinancial = !case_detail.financial_approval;

    return isInitialCase && isDsoCase && isUnapprovedFinancial;
  }

  /**
   * Determines if case is in clinical review process
   * @function
   * @return {Boolean} Whether true or not
   */
  isCaseInClinicalReview() {
    const case_detail = getCaseDetailByCaseId(this.props.case_details, this.props.case_details.case_id);
    return case_detail.clinical_review;
  }

  render() {
    const isDifferentDsoDoctor = this.isDifferentDsoDoctor();
    const isInitialWithUnapproved = this.isInitialDSOCaseWithUnapprovedFinancialStatus();
    const isInClinicalReview = this.isCaseInClinicalReview();

    if (this.props.case_details_loading || this.state.loading) {
      return (
        <div className="center">
          <CircleLoader />
        </div>
      );
    } else if (this.props.case_details_error || this.state.error) {
      return (
        <RetryButton
          className="fill center"
          message="Unable to review Smile Design."
          onRetry={() => this.props.fetchCaseDetails(this.props.case_details.case_id)}
        />
      );
    } else {
      return (
        <HistoryListItemReviewButtons
          onClickApprove={this.onClickApprove}
          onClickRevise={this.onClickRevise}
          revise_reason={this.state.revise_reason}
          onChangeReviseReason={this.onChangeReviseReason}
          disabled={this.props.disabled}
          disableApproveButton={isDifferentDsoDoctor || isInitialWithUnapproved || isInClinicalReview}
          disableReviseButton={isDifferentDsoDoctor || isInClinicalReview}
          hideApproveButton={this.props.hideApproveButton}
          hideReviseButton={this.props.hideReviseButton}
          disableApproveTooltip={
            isInitialWithUnapproved && isInClinicalReview
              ? `\u2022 ${FINANCIAL_TOOLTIP}<br>\u2022 ${CLINICAL_REVIEW_TOOLTIP}`
              : isInitialWithUnapproved
              ? FINANCIAL_TOOLTIP
              : isInClinicalReview
              ? CLINICAL_REVIEW_TOOLTIP
              : ''
          }
        />
      );
    }
  }
}

HistoryListItemReview.propTypes = {
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
    case_details_loading: getCaseDetailsLoading(state),
    case_details_error: getCaseDetailsError(state),
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseDetails: fetchCaseDetails,
      fetchCaseFileData: fetchCaseFileData,
      openFeedbackForm: openFeedbackRatingFormPostSmileDesign,
      onChangeStep: onChangeStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HistoryListItemReview);
